import React, { FunctionComponent, useState, useEffect } from 'react'
import { map, prop, head, drop } from 'ramda'
import { WithTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'

import { MainContentSection } from '../Country/types'
import StackedBarAndLine from '../../common/components/Charts/StackedBarAndLine'
import Optional from '../../common/components/Optional'
import styles from './SubDistrict.module.scss'
import { SUB_DISTRICT_PATHS } from './subDistrict.types'
import DateTime from '../DateTime'
import {
  createPercentageString,
  getValues,
  getKeys,
  getSubDistrictNavItems,
} from '../../common/utils/data'
import Tickers from '../Tickers'
import ChevronDown from '../../common/components/Icons/Chevron'

interface Props {
  districtPath: string
  mainContent: MainContentSection
  subDistrictData: any
  confirmedCasesByDistrict: any
  confirmedCasesMovingAverage: any
  recoveriesByDistrict: any
  latestStatistics: any
  dailyChanges: any
  onSelectSubDistrict: (districtPath) => void
}

const SubDistrict: FunctionComponent<Props & WithTranslation> = ({
  t,
  districtPath,
  mainContent,
  subDistrictData,
  confirmedCasesByDistrict,
  confirmedCasesMovingAverage,
  recoveriesByDistrict,
  latestStatistics,
  dailyChanges,
  onSelectSubDistrict,
}) => {
  const [activeSubDistrict, setActiveSubDistrict] = useState(false)
  const [showMobileSubDistrictNav, setShowMobileSubDistrictNav] = useState(
    false,
  )

  const sortedSubDistricts = getSubDistrictNavItems(subDistrictData)
  const subDistrictDate = prop('last_updated_on', mainContent)
  const subDistrictContent = prop('content', mainContent)

  useEffect(() => {
    !!sortedSubDistricts &&
      !activeSubDistrict &&
      updateSubDistrict(head(sortedSubDistricts))
  })

  const tickerData = [
    {
      title: 'Total Cases',
      amount: prop('confirmed', latestStatistics),
      movement: createPercentageString(
        prop('confirmedPercentage', dailyChanges),
      ),
      movementDirection: prop('confirmedPercentage', dailyChanges) > 0,
      movementPositive: prop('confirmedPercentage', dailyChanges) < 0,
    },
    {
      title: 'Total Recoveries',
      amount: prop('recoveries', latestStatistics),
      movement: createPercentageString(
        prop('recoveriesPercentage', dailyChanges),
      ),
      movementDirection: prop('recoveriesPercentage', dailyChanges) > 0,
      movementPositive: prop('recoveriesPercentage', dailyChanges) > 0,
    },
  ]

  const updateSubDistrict = (subDistrict) => {
    if (subDistrict) {
      const pageToLoad = `/south-africa${districtPath}${prop(subDistrict)(
        SUB_DISTRICT_PATHS,
      )}`
      onSelectSubDistrict({
        dataType: 'content',
        pageToLoad: pageToLoad,
      })
      onSelectSubDistrict({
        dataType: 'statistics',
        pageToLoad: pageToLoad,
      })
      setActiveSubDistrict(subDistrict)
    }
  }

  const getCurrentSubDistrictDates = () => {
    const currentDates = prop(activeSubDistrict, confirmedCasesByDistrict)
    return currentDates ? getKeys(currentDates) : []
  }

  const getCurrentSubDistrictDailyDates = () => {
    const currentDates = prop(
      `daily${activeSubDistrict}`,
      confirmedCasesByDistrict,
    )
    return currentDates ? getKeys(currentDates) : []
  }

  const getCurrentSubDistrictDailyCases = () => {
    const currentDailyCases = prop(
      `daily${activeSubDistrict}`,
      confirmedCasesByDistrict,
    )
    return currentDailyCases ? getValues(currentDailyCases) : []
  }

  const getCurrentSubDistrictCases = prop(
    activeSubDistrict,
    confirmedCasesByDistrict,
  )
    ? getValues(prop(activeSubDistrict, confirmedCasesByDistrict))
    : []

  const getCurrentSubDistrictRecoveries = prop(
    activeSubDistrict,
    recoveriesByDistrict,
  )
    ? getValues(prop(activeSubDistrict, recoveriesByDistrict))
    : []

  const subDistrictDailyCasesGraphConfig = {
    scaleY: {
      label: {
        text: 'Number of cases',
      },
      short: true,
    },
    plot: {
      stacked: false,
    },
    scaleX: {
      label: {
        text: 'Date',
      },
      values: getCurrentSubDistrictDailyDates(),
      step: 'day',
      transform: {
        type: 'date',
        all: '%M %d',
      },
    },
    series: [
      {
        type: 'bar',
        text: `${activeSubDistrict} cases`,
        values: getCurrentSubDistrictDailyCases(),
        backgroundColor: '#4973D5',
        border: '1px solid #fff',
      },
      {
        type: 'line',
        text: '7-Day Moving average',
        values: drop(1)(confirmedCasesMovingAverage),
        lineColor: '#21418D',
      },
    ],
  }

  const subDistrictCasesOverviewGraphConfig = {
    scaleY: {
      label: {
        text: 'Total Cases',
      },
      short: true,
    },
    scaleX: {
      label: {
        text: 'Date',
      },
      values: getCurrentSubDistrictDates(),
      step: 'day',
      transform: {
        type: 'date',
        all: '%M %d',
      },
    },
    plot: { stacked: false },
    series: [
      {
        text: 'Confirmed cases',
        lineColor: '#21418D',
        type: 'line',
        values: getCurrentSubDistrictCases,
      },
      {
        text: 'Recoveries',
        lineColor: '#7796DF',
        type: 'line',
        values: getCurrentSubDistrictRecoveries,
      },
    ],
  }

  return (
    <section id="sub-district-info" className={styles.subDistrictInfoWrapper}>
      <div className={styles.desktopNavigation}>
        <ul>
          {map(
            (subDistrict) => (
              <li key={subDistrict}>
                <button
                  className={
                    subDistrict === activeSubDistrict ? styles.active : ''
                  }
                  onClick={() => updateSubDistrict(subDistrict)}
                >
                  {t(subDistrict)}
                </button>
              </li>
            ),
            sortedSubDistricts,
          )}
        </ul>
      </div>
      <div className={styles.content}>
        <div className={styles.mobileNavigation}>
          <div
            className={`${styles.navDropdown} ${
              showMobileSubDistrictNav ? styles.active : ''
            }`}
            onClick={() => {
              setShowMobileSubDistrictNav(!showMobileSubDistrictNav)
            }}
          >
            <div>
              <h6>{t('View Subdistrict')}</h6>
              {activeSubDistrict && <span>{t(`${activeSubDistrict}`)}</span>}
            </div>
            <ChevronDown />
            <ul className={styles.navDropdownItems}>
              {map(
                (subDistrict) => (
                  <li key={subDistrict}>
                    <button
                      className={
                        subDistrict === activeSubDistrict ? styles.active : ''
                      }
                      onClick={() => updateSubDistrict(subDistrict)}
                    >
                      {t(subDistrict)}
                    </button>
                  </li>
                ),
                sortedSubDistricts,
              )}
            </ul>
          </div>
        </div>
        <Optional condition={!!activeSubDistrict && !!subDistrictContent}>
          <h3>{t(`${activeSubDistrict}`)} Sub-district</h3>

          {subDistrictDate && <DateTime date={subDistrictDate} />}
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(subDistrictContent),
            }}
          />
          <Tickers tickerData={tickerData} />
          <h5>Sub-district Daily Cases & 7-Day Moving Average</h5>
          <StackedBarAndLine chartData={subDistrictDailyCasesGraphConfig} />
          <h5>Sub-district Cases & Recoveries</h5>
          <StackedBarAndLine chartData={subDistrictCasesOverviewGraphConfig} />
        </Optional>
      </div>
    </section>
  )
}

export default SubDistrict
