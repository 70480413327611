import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Country from './Country.component'
import {
  getMainContentSelector,
  getDailyCasesContentSelector,
  getDeathsOverviewContentSelector,
  getNationalTestingContentSelector,
  getHospitalisationsContentSelector,
  getLatestStatisticsSelector,
  getDailyChangesSelector,
  getConfirmedCasesByProvinceSelector,
  getConfirmedCasesMovingAverageSelector,
  getNationalCasesContentSelector,
  getNationalDeathsSelector,
  getNationalRecoveriesSelector,
  getNationalActiveCasesSelector,
  getDeathsPerProvinceSelector,
  getStatisticsDatesSelector,
  getAdmissionsSelector,
  getIcuCasesSelector,
  getVentilatorCasesSelector,
  getHospitalisationsDatesSelector,
  getTestingDatesSelector,
  getPublicTestingStatsSelector,
  getPrivateTestingStatsSelector,
  getDailyTestMovingAverageSelector,
  getDailyPositiveCasesSelector,
  getPositiveTestDatesSelector,
  getDailyCasesDatesSelector,
  getProvincialComparisonsContentSelector,
} from './country.selectors'

const mapStateToProps = (state) => ({
  mainContent: getMainContentSelector(state),
  dailyCasesContent: getDailyCasesContentSelector(state),
  deathsContent: getDeathsOverviewContentSelector(state),
  nationalTestingContent: getNationalTestingContentSelector(state),
  hospitalisationsContent: getHospitalisationsContentSelector(state),
  provincialComparisonsContent: getProvincialComparisonsContentSelector(state),
  nationalCasesContent: getNationalCasesContentSelector(state),
  latestStatistics: getLatestStatisticsSelector(state),
  dailyChanges: getDailyChangesSelector(state),
  confirmedCasesByProvince: getConfirmedCasesByProvinceSelector(state),
  confirmedCasesMovingAverage: getConfirmedCasesMovingAverageSelector(state),
  nationalDeaths: getNationalDeathsSelector(state),
  nationalRecoveries: getNationalRecoveriesSelector(state),
  nationalActiveCases: getNationalActiveCasesSelector(state),
  deathsPerProvince: getDeathsPerProvinceSelector(state),
  statisticsDates: getStatisticsDatesSelector(state),
  currentlyAdmitted: getAdmissionsSelector(state),
  icuCases: getIcuCasesSelector(state),
  onVentilation: getVentilatorCasesSelector(state),
  hospitalisationsDates: getHospitalisationsDatesSelector(state),
  testingDates: getTestingDatesSelector(state),
  publicTestingStats: getPublicTestingStatsSelector(state),
  privateTestingStats: getPrivateTestingStatsSelector(state),
  dailyTestMovingAverage: getDailyTestMovingAverageSelector(state),
  positiveTests: getDailyPositiveCasesSelector(state),
  positiveTestsDates: getPositiveTestDatesSelector(state),
  dailyCasesDates: getDailyCasesDatesSelector(state),
})

export default connect(mapStateToProps)(withTranslation()(Country))
