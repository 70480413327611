import React, { FC } from 'react'
import DOMPurify from 'dompurify'

import DateTime from '../DateTime'
import { MainContent } from './types'

const Component: FC<MainContent> = ({ title, date, content, children }) => {
  return title ? (
    <section id="national-map">
      <h1>{title}</h1>
      {date && <DateTime date={date} />}
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
      {children}
    </section>
  ) : null
}

export default Component
