import React, { FunctionComponent } from 'react'
import Optional from '../Optional'
import LoaderFallback from '../Loader/Loader.container'

interface Props {
  isLoading: any
  Loader?: any
  children: any
}

const LoadUntilReady: FunctionComponent<Props> = ({
  isLoading,
  Loader,
  children,
}) => {
  return (
    <Optional
      condition={!isLoading}
      Fallback={Loader ? Loader : <LoaderFallback />}
    >
      {children}
    </Optional>
  )
}

export default LoadUntilReady
