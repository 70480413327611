import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

//---------------------------------
// actions
//---------------------------------

export const API_LOAD_COUNTRY_PAGE_DATA = '[country] fetch page content'
export const loadCountryPageData = (payload): AnyAction => ({
  type: API_LOAD_COUNTRY_PAGE_DATA,
  payload,
})

export const API_LOAD_COUNTRY_PAGE_DATA_SUCCESS =
  '[country] fetch page content success'
export const loadCountryPageDataSuccess = (payload): AnyAction => ({
  type: API_LOAD_COUNTRY_PAGE_DATA_SUCCESS,
  payload,
})
export const SET_COUNTRY_PAGE_CONTENT = '[country] set page content'
export const setPageContent = (payload): AnyAction => ({
  type: SET_COUNTRY_PAGE_CONTENT,
  payload,
})

export const SET_COUNTRY_STATISTICS_DATA = '[country] set statistics data'
export const setStatisticsData = (payload): AnyAction => ({
  type: SET_COUNTRY_STATISTICS_DATA,
  payload,
})

export const API_LOAD_COUNTRY_PAGE_DATA_FAILURE =
  '[country] set page content failure'
export const loadPageDataFailure = (): AnyAction => ({
  type: API_LOAD_COUNTRY_PAGE_DATA_FAILURE,
})

//---------------------------------
// reducers
//---------------------------------

const overwriteContent = (state, { payload }): any =>
  assoc('content', payload, state)

const overwriteStatistics = (state, { payload }): any =>
  assoc('statistics', payload, state)

//---------------------------------
// reducer
//---------------------------------

export const INITIAL_STATE = {
  content: {},
  statistics: {
    caseHistory: {
      confirmed: { ZA: [], dailyZA: [] },
      deaths: { ZA: [] },
      recoveries: { ZA: [] },
      active: { ZA: [] },
    },
    movingAverage: {
      confirmed: {},
    },
    hospitalisations: {
      currentlyAdmitted: { ZA: {} },
      icu: { ZA: {} },
      ventilated: { ZA: {} },
    },
    testing: {
      cumulative: { ZA: [] },
      public: { dailyZA: [] },
      private: { dailyZA: [] },
      dailyTestMovingAverage: {},
      dailyPositive: {
        positiveTestData: [],
        positiveTestDates: [],
      },
    },
  },
}

export const reducer = createReducer(INITIAL_STATE as any, {
  [SET_COUNTRY_PAGE_CONTENT]: overwriteContent,
  [SET_COUNTRY_STATISTICS_DATA]: overwriteStatistics,
})
