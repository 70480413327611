export interface Stats {
  confirmed: number
  deaths: number
  recoveries: number
  active: number
}

export interface DailyChanges {
  confirmedPercentage: number
  deathsPercentage: number
  recoveriesPercentage: number
  activePercentage: number
  newCases: number
  newDeaths: number
}

export interface MainContentSection {
  last_updated_on: string
  content: string
}

export interface PageContentSection {
  last_updated_on: string
  display_title: string
  content: string
}

export interface ContentPageContentState {
  content: {
    highlights: PageContentSection
    daily_cases: PageContentSection
    cases_overview: PageContentSection
    deaths_overview: PageContentSection
    national_testing: {
      'last_updated_on': string
      'display_title': string
      'content': string
      '7_day_average': {
        content: string
      }
      'national_daily_positive_tests': {
        content: string
      }
    }
    hospitalisations: PageContentSection
    provincial_cases: {
      last_updated_on: string
      display_title: string
      content: string
      deaths: {
        content: string
      }
      cases: {
        content: string
      }
    }
  }
}

interface latestDataLocation {
  confirmed: number
  deaths: number
  recoveries: number
  active: number
}

export interface DailyChangesData {
  confirmedPercentage: number
  deathsPercentage: number
  recoveriesPercentage: number
  activePercentage: number
  newCases: number
  newDeaths: number
  newHospitalisations: number
  newCasesPercentage: number
  newDeathsPercentage: number
  newHospitalisationsPercentage: number
}

export interface LatestStatistics {
  U: latestDataLocation
  EC: latestDataLocation
  NC: latestDataLocation
  LP: latestDataLocation
  NW: latestDataLocation
  MP: latestDataLocation
  FS: latestDataLocation
  KZN: latestDataLocation
  WC: latestDataLocation
  GP: latestDataLocation
  ZA: latestDataLocation
}

export interface ContentPageStatisticsState {
  latest: LatestStatistics
  dailyChanges: DailyChangesData
  caseHistory: any
  movingAverage: any
}

export enum ProvincialComparisonChartTypes {
  CASES = 'cases',
  DEATHS = 'deaths',
}

export enum ProvincialComparisonChartStatisticsProp {
  cases = 'confirmed',
  deaths = 'deaths',
}
