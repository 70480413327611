import React, { FC } from 'react'

import Clock from '../../common/components/Icons/Clock'
import { getPrettyDate } from '../../common/utils/data'
import styles from './DateTime.module.scss'

interface Props {
  date: number
  style?: React.CSSProperties
}

const DateTime: FC<Props> = ({ date, style = {} }) => {
  return (
    <div className={styles.dateStamp} style={style}>
      <Clock />
      {getPrettyDate(date)}
    </div>
  )
}

export default DateTime
