import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Province from './Province.component'
import {
  getMainContentSelector,
  getDailyCasesContentSelector,
  getProvincialCasesContentSelector,
  getDeathsContentSelector,
  getHospitalisationsContentSelector,
  getDailyChangesSelector,
  getAdmissionsSelector,
  getIcuCasesSelector,
  getVentilatorCasesSelector,
  getProvincialDeathsSelector,
  getNationalDeathsSelector,
  getProvincialRecoveriesSelector,
  getProvincialActiveCasesSelector,
  getStatisticsDatesSelector,
  getHospitalisationsDatesSelector,
  getLatestProvincialStatisticsSelector,
  getCurrentPageProvinceCodeSelector,
  getDailyCasesMovingAverageSelector,
  getProvincialDailyCasesSelector,
  getProvincialDailyCasesDatesSelector,
  getLatestNationalStatisticsSelector,
} from './province.selectors'
import { PROVINCE_PATHS } from './Province.types'

const mapStateToProps = (state) => ({
  provinceCode: getCurrentPageProvinceCodeSelector(PROVINCE_PATHS)(state),
  mainContent: getMainContentSelector(state),
  dailyCasesContent: getDailyCasesContentSelector(state),
  provincialCasesContent: getProvincialCasesContentSelector(state),
  deathsContent: getDeathsContentSelector(state),
  hospitalisationsContent: getHospitalisationsContentSelector(state),
  dailyChanges: getDailyChangesSelector(state),
  currentlyAdmitted: getAdmissionsSelector(state),
  icuCases: getIcuCasesSelector(state),
  onVentilation: getVentilatorCasesSelector(state),
  nationalDeaths: getNationalDeathsSelector(state),
  provincialDeaths: getProvincialDeathsSelector(PROVINCE_PATHS)(state),
  provincialRecoveries: getProvincialRecoveriesSelector(PROVINCE_PATHS)(state),
  provincialActiveCases: getProvincialActiveCasesSelector(PROVINCE_PATHS)(
    state,
  ),
  provincialDailyCases: getProvincialDailyCasesSelector(PROVINCE_PATHS)(state),
  provincialDailyCasesDates: getProvincialDailyCasesDatesSelector(
    PROVINCE_PATHS,
  )(state),
  statisticsDates: getStatisticsDatesSelector(state),
  hospitalisationsDates: getHospitalisationsDatesSelector(state),
  latestProvincialStatistics: getLatestProvincialStatisticsSelector(state),
  latestNationalStatistics: getLatestNationalStatisticsSelector(state),
  dailyCasesMovingAverage: getDailyCasesMovingAverageSelector(state),
})

export default connect(mapStateToProps)(withTranslation()(Province))
