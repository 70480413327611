import { createSelector } from 'reselect'
import { path, compose } from 'ramda'

import { getValues } from '../../common/utils/data'

export const getCurrentPageDistrictPath = path([
  'router',
  'location',
  'pathname',
])

export const getCurrentPageDistrictCodeSelector = createSelector(
  [getCurrentPageDistrictPath],
  (content) => content,
)

export const getMainContent = path(['subDistrict', 'content', 'highlights'])
export const getMainContentSelector = createSelector(
  [getMainContent],
  (content) => content,
)

export const getDailyCasesMovingAverage = compose(
  getValues,
  path(['subDistrict', 'statistics', 'movingAverage', 'confirmed']),
)

export const getDailyCasesMovingAverageSelector = createSelector(
  [getDailyCasesMovingAverage],
  (content) => content,
)

export const getConfirmedCasesByDistrict = path([
  'subDistrict',
  'statistics',
  'caseHistory',
  'confirmed',
])

export const getConfirmedCasesByDistrictSelector = createSelector(
  [getConfirmedCasesByDistrict],
  (content) => content,
)

export const getRecoveriesByDistrict = path([
  'subDistrict',
  'statistics',
  'caseHistory',
  'recoveries',
])

export const getRecoveriesByDistrictSelector = createSelector(
  [getRecoveriesByDistrict],
  (content) => content,
)

export const getLatestStatistics = path(['subDistrict', 'statistics', 'latest'])

export const getLatestStatisticsSelector = createSelector(
  [getLatestStatistics],
  (content) => content,
)

export const getDailyChanges = path([
  'subDistrict',
  'statistics',
  'dailyChanges',
])

export const getDailyChangesSelector = createSelector(
  [getDailyChanges],
  (content) => content,
)
