import React from 'react'

import { IconProps } from './types'

const Instagram = (props: IconProps): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    width={props.width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || '#000'}
      d="M19.23 6.09a1.33 1.33 0 11-1.32-1.32 1.32 1.32 0 011.32 1.32zM23 16.56a6.64 6.64 0 01-1.8 4.64 6.56 6.56 0 01-4.64 1.8c-1.82.1-7.3.1-9.12 0a6.62 6.62 0 01-4.64-1.8A6.59 6.59 0 011 16.56c-.1-1.82-.1-7.3 0-9.13A6.55 6.55 0 012.8 2.8 6.59 6.59 0 017.44 1c1.82-.1 7.3-.1 9.12 0a6.6 6.6 0 014.64 1.8A6.59 6.59 0 0123 7.44c.09 1.82.09 7.3 0 9.12zM21.07 12c0-1.61.13-5.07-.44-6.52a3.77 3.77 0 00-2.11-2.11c-1.45-.57-4.91-.44-6.52-.44s-5.06-.13-6.52.44a3.73 3.73 0 00-2.1 2.11c-.57 1.45-.45 4.91-.45 6.52s-.13 5.07.45 6.52a3.76 3.76 0 002.1 2.11c1.45.57 4.91.44 6.52.44s5.07.13 6.52-.44a3.74 3.74 0 002.11-2.11c.57-1.45.44-4.91.44-6.52zm-3.4 0A5.67 5.67 0 1112 6.33 5.66 5.66 0 0117.67 12zm-2 0A3.69 3.69 0 1012 15.69 3.69 3.69 0 0015.69 12z"
    />
  </svg>
)

export default Instagram
