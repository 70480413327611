import {
  loadGeoJsonFlow,
  loadGeoJsonSuccessFlow,
} from '../../modules/Maps/components/Map'
import { updateMapAvailability } from '../../modules/App'
import {
  loadCountryPageDataFlow,
  loadCountryPageDataSuccessFlow,
} from '../../modules/Country'
import {
  loadPageDataFlow,
  loadPageDataSuccessFlow,
} from '../../modules/Province'
import {
  loadDistrictPageDataFlow,
  loadDistrictPageDataSuccessFlow,
} from '../../modules/District'
import {
  loadSubDistrictPageDataFlow,
  loadSubDistrictPageDataSuccessFlow,
} from '../../modules/SubDistrict'
import {
  loadLatestArticlesDataFlow,
  loadLatestArticlesDataSuccessFlow,
  loadMostReadArticlesDataFlow,
  loadMostReadArticlesDataSuccessFlow,
} from '../../modules/NewsArticles'
import { pageChangeFlow } from '../../modules/App'

import zingchart from '../zingchart'

export default [
  loadGeoJsonFlow(zingchart),
  loadGeoJsonSuccessFlow(updateMapAvailability),
  loadCountryPageDataFlow,
  loadCountryPageDataSuccessFlow,
  loadPageDataFlow,
  loadPageDataSuccessFlow,
  pageChangeFlow,
  loadDistrictPageDataFlow,
  loadDistrictPageDataSuccessFlow,
  loadSubDistrictPageDataFlow,
  loadSubDistrictPageDataSuccessFlow,
  loadLatestArticlesDataFlow,
  loadLatestArticlesDataSuccessFlow,
  loadMostReadArticlesDataFlow,
  loadMostReadArticlesDataSuccessFlow,
]
