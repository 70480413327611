import React, { FC } from 'react'

import styles from './FeatureArticle.module.scss'
import DateTime from '../DateTime'
import Optional from '../../common/components/Optional'
import { __NEWS24SITE__ } from '../../common/constants'

interface Props {
  date?: string
  title?: string
  image?: string
  synopsis?: string
  link?: string
  shouldDisplay: boolean
}

const FeatureArticle: FC<Props> | null = ({
  date,
  title,
  image,
  synopsis,
  link,
  shouldDisplay,
}) => {
  return (
    <Optional condition={shouldDisplay && __NEWS24SITE__}>
      <div className={styles.container}>
        <a
          className={styles.articleLink}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.card}>
            <div className={styles.dateStamp}>
              {date && (
                <DateTime
                  date={new Date(date).getTime()}
                  style={{ margin: 0 }}
                />
              )}
            </div>
            <h4 className={styles.title}>{title}</h4>
            <div className={styles.content}>
              <img src={image} alt={title} className={styles.image} />
              <div className={styles.synopsis}>{synopsis}</div>
            </div>
          </div>
        </a>
      </div>
    </Optional>
  )
}

export default FeatureArticle
