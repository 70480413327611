import numeral from 'numeral'

numeral.localeData().delimiters.thousands = ' '

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const debounce = (method: any, time: number): void => {
  clearTimeout(method._tId)
  method._tId = setTimeout(() => {
    method()
  }, time)
}

export const formatNumberWithSpaces = (number: number): string =>
  numeral(number).format('0,0')
