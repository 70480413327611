import { ProvinceChartConfig } from './Charts.types'
import { PROVINCE_CODES } from '../../../modules/Province/Province.types'

export const provinceData: ProvinceChartConfig = [
  { text: PROVINCE_CODES.WC, lineColor: '#2a51ac' },
  { text: PROVINCE_CODES.GP, lineColor: '#68c6fa' },
  { text: PROVINCE_CODES.NW, lineColor: '#82c459' },
  { text: PROVINCE_CODES.NC, lineColor: '#e9d25f' },
  { text: PROVINCE_CODES.FS, lineColor: '#f5931f' },
  { text: PROVINCE_CODES.EC, lineColor: '#e23036' },
  { text: PROVINCE_CODES.MP, lineColor: '#d86d9c' },
  { text: PROVINCE_CODES.LP, lineColor: '#a177c3' },
  { text: PROVINCE_CODES.KZN, lineColor: '#211717' },
]

export enum brandColors {
  en = '#21418d',
  af = '#a4a4a4',
}
