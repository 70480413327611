import { AnyAction } from '@reduxjs/toolkit'

import {
  ZC_LOAD_GEO_JSON,
  ZC_LOAD_GEO_JSON_SUCCESS,
  loadGeoJsonSuccess,
} from './map.reducer'

import { dispatch as DispatchType } from '../../../../common/redux/types'

//---------------------------------
// set district map data
//---------------------------------

export const loadGeoJsonFlow = (zingchart: any) => ({
  dispatch,
}: DispatchType) => (next: (action: AnyAction) => void) => (
  action: AnyAction,
): void => {
  next(action)

  const { type, payload } = action

  if (type === ZC_LOAD_GEO_JSON) {
    zingchart.maps.loadGeoJSON({
      id: payload,
      url: `${process.env.REACT_APP_GEO_JSON_URL}/${payload}.geo.json`,
      callback: () => {
        dispatch(loadGeoJsonSuccess(payload))
      },
    })
  }
}

export const loadGeoJsonSuccessFlow = (
  updateMapAvailability: (payload: string) => AnyAction,
) => ({ dispatch }: DispatchType) => (next: (action: AnyAction) => void) => (
  action: AnyAction,
): void => {
  next(action)

  const { type, payload } = action

  if (type === ZC_LOAD_GEO_JSON_SUCCESS) {
    dispatch(updateMapAvailability(payload))
  }
}
