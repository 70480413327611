import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

//---------------------------------
// actions
//---------------------------------

export const API_LOAD_LATEST_ARTICLES_DATA =
  '[news articles] API_LOAD_LATEST_ARTICLES_DATA'
export const loadLatestArticlesData = (): AnyAction => ({
  type: API_LOAD_LATEST_ARTICLES_DATA,
})

export const API_LOAD_LATEST_ARTICLES_DATA_SUCCESS =
  '[news articles] API_LOAD_LATEST_ARTICLES_DATA_SUCCESS'
export const loadLatestArticlesDataSuccess = (payload: any[]): AnyAction => ({
  type: API_LOAD_LATEST_ARTICLES_DATA_SUCCESS,
  payload,
})

export const API_LOAD_LATEST_ARTICLES_DATA_FAILURE =
  '[news articles] API_LOAD_LATEST_ARTICLES_DATA_FAILURE'
export const loadLatestArticlesDataFailure = (error: any): AnyAction => ({
  type: API_LOAD_LATEST_ARTICLES_DATA_FAILURE,
  error,
})

export const SET_LATEST_ARTICLES = '[news articles] SET_LATEST_ARTICLES'
export const setLatestArticles = (payload: any[]): AnyAction => ({
  type: SET_LATEST_ARTICLES,
  payload,
})

export const API_LOAD_MOST_READ_ARTICLES_DATA =
  '[news articles] API_LOAD_MOST_READ_ARTICLES_DATA'
export const loadMostReadArticlesData = (): AnyAction => ({
  type: API_LOAD_MOST_READ_ARTICLES_DATA,
})

export const API_LOAD_MOST_READ_ARTICLES_DATA_SUCCESS =
  '[news articles] API_LOAD_MOST_READ_ARTICLES_DATA_SUCCESS'
export const loadMostReadArticlesDataSuccess = (payload: any[]): AnyAction => ({
  type: API_LOAD_MOST_READ_ARTICLES_DATA_SUCCESS,
  payload,
})

export const API_LOAD_MOST_READ_ARTICLES_DATA_FAILURE =
  '[news articles] API_LOAD_MOST_READ_ARTICLES_DATA_FAILURE'
export const loadMostReadArticlesDataFailure = (error: any): AnyAction => ({
  type: API_LOAD_MOST_READ_ARTICLES_DATA_FAILURE,
  error,
})

export const SET_MOST_READ_ARTICLES = '[news articles] SET_MOST_READ_ARTICLES'
export const setMostReadArticles = (payload: any[]): AnyAction => ({
  type: SET_MOST_READ_ARTICLES,
  payload,
})

//---------------------------------
// reducers
//---------------------------------

const overwriteLatestArticles = (state, { payload }): any =>
  assoc('latestArticles', payload, state)

const overwriteMostReadArticles = (state, { payload }): any =>
  assoc('mostReadArticles', payload, state)

//---------------------------------
// reducer
//---------------------------------

export const INITIAL_STATE = {
  latestArticles: [],
  mostReadArticles: [],
}

export const reducer = createReducer(INITIAL_STATE as any, {
  [SET_LATEST_ARTICLES]: overwriteLatestArticles,
  [SET_MOST_READ_ARTICLES]: overwriteMostReadArticles,
})
