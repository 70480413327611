import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import District from './District.component'
import {
  getCurrentPageDistrictCodeSelector,
  getMainContentSelector,
  getDailyCasesContentSelector,
  getLatestStatisticsSelector,
  getDailyChangesSelector,
  getConfirmedCasesByDistrictSelector,
  getConfirmedCasesMovingAverageSelector,
  getDistrictCasesContentSelector,
  getCasesBySubDistrictContentSelector,
  getConfirmedCasesByDistrictDatesSelector,
  getRecoveriesByDistrictSelector,
} from './district.selectors'
import { DISTRICT_PATHS } from './district.types'

const mapStateToProps = (state) => ({
  districtCode: getCurrentPageDistrictCodeSelector(DISTRICT_PATHS)(state),
  mainContent: getMainContentSelector(state),
  dailyCasesContent: getDailyCasesContentSelector(state),
  casesOverview: getDistrictCasesContentSelector(state),
  casesBySubDistrictContent: getCasesBySubDistrictContentSelector(state),
  dailyCasesDates: getConfirmedCasesByDistrictDatesSelector(state),
  confirmedCasesByDistrict: getConfirmedCasesByDistrictSelector(state),
  confirmedCasesMovingAverage: getConfirmedCasesMovingAverageSelector(state),
  recoveriesByDistrict: getRecoveriesByDistrictSelector(state),
  dailyChanges: getDailyChangesSelector(state),
  latestStatistics: getLatestStatisticsSelector(state),
})

export default connect(mapStateToProps)(withTranslation()(District))
