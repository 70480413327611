import React, { FunctionComponent } from 'react'
import { WithTranslation } from 'react-i18next'

import LoadingGrid from '../Icons/LoadingGrid'
import styles from './Loader.module.scss'

const Loader: FunctionComponent<WithTranslation> = ({ t }) => (
  <div className={styles.loader}>
    <LoadingGrid width="42" fill="#21418d" />
    <div className={styles.loadingText}>
      <h4>{t('loadingText')}</h4>
    </div>
  </div>
)

export default Loader
