import { createSelector } from 'reselect'
import { path, prop } from 'ramda'

import { MapDetails } from '../Maps/components/Map/types'

const getCurrentMapAvailability = (state, props: MapDetails) =>
  path(['availableMaps', prop('mapName', props)], state)

export const getCurrentMapAvailabilitySelector = createSelector(
  [getCurrentMapAvailability],
  (currentMap) => currentMap,
)
