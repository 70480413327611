import React, { SFC } from 'react'
import { prop } from 'ramda'
import DOMPurify from 'dompurify'

import DateTime from '../DateTime'
import { PageContentSection } from '../Country/types'

interface Props {
  sectionName?: string
  content: PageContentSection
}

const ContentSection: SFC<Props> = ({ sectionName, content, children }) => (
  <section id={sectionName || ''}>
    <h3>{prop('display_title', content)}</h3>
    <DateTime date={prop('last_updated_on', content)} />
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(prop('content', content)),
      }}
    />
    {children}
  </section>
)

export default ContentSection
