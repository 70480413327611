import { AnyAction } from '@reduxjs/toolkit'

import { dispatch as DispatchType } from '../../common/redux/types'
import axios from 'axios'
import {
  API_LOAD_LATEST_ARTICLES_DATA,
  API_LOAD_LATEST_ARTICLES_DATA_SUCCESS,
  API_LOAD_MOST_READ_ARTICLES_DATA,
  API_LOAD_MOST_READ_ARTICLES_DATA_SUCCESS,
  loadLatestArticlesDataFailure,
  loadLatestArticlesDataSuccess,
  loadMostReadArticlesDataFailure,
  loadMostReadArticlesDataSuccess,
  setLatestArticles,
  setMostReadArticles,
} from './NewsArticles.reducer'

export const loadLatestArticlesDataFlow = ({ dispatch }: DispatchType) => (
  next: (action: AnyAction) => void,
) => (action: AnyAction): void => {
  next(action)

  const { type } = action

  if (type === API_LOAD_LATEST_ARTICLES_DATA) {
    process.env.REACT_APP_NEWS24_LATEST_ARTICLES_URL &&
      axios
        .get(process.env.REACT_APP_NEWS24_LATEST_ARTICLES_URL)
        .then((response) => {
          dispatch(loadLatestArticlesDataSuccess(response.data))
        })
        .catch(loadLatestArticlesDataFailure)
  }
}

export const loadLatestArticlesDataSuccessFlow = ({
  dispatch,
}: DispatchType) => (next: (action: AnyAction) => void) => (
  action: AnyAction,
): void => {
  next(action)

  const { type, payload } = action

  if (type === API_LOAD_LATEST_ARTICLES_DATA_SUCCESS) {
    dispatch(setLatestArticles(payload))
  }
}

export const loadMostReadArticlesDataFlow = ({ dispatch }: DispatchType) => (
  next: (action: AnyAction) => void,
) => (action: AnyAction): void => {
  next(action)

  const { type } = action

  if (type === API_LOAD_MOST_READ_ARTICLES_DATA) {
    process.env.REACT_APP_NEWS24_MOST_READ_ARTICLES_URL &&
      axios
        .get(process.env.REACT_APP_NEWS24_MOST_READ_ARTICLES_URL)
        .then((response) => {
          dispatch(loadMostReadArticlesDataSuccess(response.data))
        })
        .catch(loadMostReadArticlesDataFailure)
  }
}

export const loadMostReadArticlesDataSuccessFlow = ({
  dispatch,
}: DispatchType) => (next: (action: AnyAction) => void) => (
  action: AnyAction,
): void => {
  next(action)

  const { type, payload } = action

  if (type === API_LOAD_MOST_READ_ARTICLES_DATA_SUCCESS) {
    dispatch(setMostReadArticles(payload))
  }
}
