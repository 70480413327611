import { createSelector } from '@reduxjs/toolkit'
import { applySpec, prop, path, isNil, complement } from 'ramda'

import { getFeaturedArticleSelector } from '../NewsArticles/NewsArticles.selector'

const selector = createSelector(
  [getFeaturedArticleSelector],
  applySpec({
    date: prop('displayDate'),
    title: prop('title'),
    image: path(['thumbnails', 'medium']),
    synopsis: prop('synopsis'),
    link: prop('articleUrl'),
    shouldDisplay: complement(isNil),
  }),
)

export default selector
