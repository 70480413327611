export enum DISTRICT_PATHS {
  '/western-cape/city-of-cape-town' = 'COCT',
  '/western-cape/west-coast' = 'WestCoast',
  '/western-cape/cape-winelands' = 'CapeWinelands',
  '/western-cape/overberg' = 'Overberg',
  '/western-cape/garden-route' = 'GardenRoute',
  '/western-cape/central-karoo' = 'CentralKaroo',
  '/gauteng/sedibeng' = 'Sedibeng',
  '/gauteng/west-rand' = 'WestRand',
  '/gauteng/ekurhuleni' = 'Ekurhuleni',
  '/gauteng/johannesburg' = 'COJ',
  '/gauteng/tshwane' = 'COT',
}

export enum DISTRICT_NAMES {
  COCT = 'City of Cape Town',
  WestCoast = 'West Coast',
  CapeWinelands = 'Cape Winelands',
  Overberg = 'Overberg',
  GardenRoute = 'Garden Route',
  CentralKaroo = 'Central Karoo',
  Sedibeng = 'Sedibeng',
  WestRand = 'West Rand',
  Ekurhuleni = 'Ekurhuleni',
  COJ = 'Johannesburg',
  COT = 'Tshwane',
}

export enum SUB_DISTRICT_NAMES {
  Western = 'Western',
  Southern = 'Southern',
  Klipfontein = 'Klipfontein',
  MitchellsPlain = 'Mitchells Plain',
  Tygerberg = 'Tygerberg',
  Khayelitsha = 'Khayelitsha',
  Eastern = 'Eastern',
  Northern = 'Northern',
}

export interface DISTRICT_LINK_PROPERTIES {
  path: string
  title: string
}
