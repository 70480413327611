import React, { FunctionComponent, useContext, useEffect, useRef } from 'react'
import { AdPlatforms, AdSlot as AdSlotType } from './Ads.types'
import { AdSlotContext } from './AdsProvider'
import { getAdSlotStyleClass } from './Ads.utils'
import Styles from './AdSlot.module.scss'

interface Props extends Omit<AdSlotType, 'platform'> {
  platform?: AdPlatforms
}
const AdSlot: FunctionComponent<Props> = ({
  name,
  section,
  platform = AdPlatforms.WEB,
}) => {
  const didMountRef = useRef(false)
  const { addAdSlot } = useContext(AdSlotContext)

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
      addAdSlot!({ section, name, platform } as AdSlotType)
    }
  }, [section, name, platform, addAdSlot])

  const className = getAdSlotStyleClass(platform)

  return (
    <div className={className}>
      <div id={name} className={Styles.displayAdWrapper} />
    </div>
  )
}

export default AdSlot
