import { connect } from 'react-redux'

import Map from './Map.component'
import { loadGeoJson } from './map.reducer'
import { getCurrentMapAvailabilitySelector } from '../../../App/app.selectors'
import { RootState } from '../../../../common/redux/types'
import { MapDetails } from './types'

const mapStateToProps = (state: RootState, props: MapDetails) => ({
  isAvailable: getCurrentMapAvailabilitySelector(state, props),
})

const mapDispatchToProps = {
  onLoadGeoJson: loadGeoJson,
}

export default connect(mapStateToProps, mapDispatchToProps)(Map)
