import React from 'react'

import { IconProps } from './types'

const Close = (props: IconProps): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    width={props.width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || '#000'}
      d="M13.65 12.004L18.668 7a1.171 1.171 0 10-1.657-1.657l-5.004 5.016-5.004-5.016A1.171 1.171 0 005.345 7l5.016 5.004-5.016 5.005a1.165 1.165 0 000 1.656 1.165 1.165 0 001.657 0l5.004-5.016 5.004 5.016a1.166 1.166 0 001.657 0 1.164 1.164 0 000-1.656l-5.016-5.005z"
    />
  </svg>
)

export default Close
