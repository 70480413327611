import React, { FunctionComponent } from 'react'
import { map, prop } from 'ramda'
import DOMPurify from 'dompurify'
import { WithTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import styles from './AboutTheData.module.scss'
import AdsProvider, { AdSlot, AdNames, AdPlatforms, AdSections } from '../Ads'
import { generateReactKey } from '../../common/utils/hooks.utils'

const AboutTheData: FunctionComponent<WithTranslation> = ({ t }) => {
  const content = [
    {
      content:
        "<p class='highlight'>Data for this dashboard is collated from a variety of public sources. There is much uncertainty about the accuracy and completeness of officially reported Covid-19 data, due to several factors including the variations in data reporting by healthcare facilities at a provincial and local level, and the accessibility as well as availability of testing.</p>",
    },

    {
      display_title: 'National Overview',
      image_url: './images/graphs-and-maps/national-overview.png',
      content:
        '<p>Our map is connected to the number of ‘active cases’ observed daily in each province. Active cases is a crude metric to measure Covid-19 and is obtained by subtracting the number of reported deaths and recoveries from the number of reported cases. Epidemiologists have warned against using this metric, due to time delays between cases being confirmed and recovery, incomplete follow-ups of cases resulting in deaths and recoveries not being well-documented and an inconsistent application of the definition of a recovery. <a href="https://www.news24.com/news24/southafrica/investigations/graphics-gauteng-now-recording-more-covid-19-cases-a-day-than-the-western-cape-has-at-any-point-20200627" target="_blank" rel="noopener noreferrer">Read more</a></p>',
      sources: [
        {
          title: 'National Department of Health',
          link:
            'https://sacoronavirus.co.za/category/press-releases-and-notices',
        },
        {
          title: 'NICD',
          link:
            'https://www.nicd.ac.za/diseases-a-z-index/covid-19/surveillance-reports',
        },
      ],
    },
    {
      display_title: 'Testing',
      image_url: './images/graphs-and-maps/testing-graph.png',
      content:
        '<p>Testing data is subject to the highest degree of uncertainty, due to unknown variables such as test kit shortages, extraction kit shortages and delays in reporting. The department of health also does not report positive cases by date of sample collection, making the trends found in analysis of daily testing and daily case numbers an indication only. The National Institute for Communicable Diseases analyses confirmed cases by date of test sample collection or delivery, but the underlying data is not publicly available as of August 2020. Daily testing data by province is not publicly available, and where possible weekly testing data published by the NICD is used.</p>',
      sources: [
        {
          title: 'National Department of Health',
          link:
            'https://sacoronavirus.co.za/category/press-releases-and-notices/',
        },
        {
          title: 'NICD',
          link:
            'https://www.nicd.ac.za/diseases-a-z-index/covid-19/surveillance-reports/',
        },
      ],
    },

    {
      display_title: 'Hospitalisations',
      image_url: './images/graphs-and-maps/hospitalisation-graph.png',
      content:
        '<p>Hospitalisation data is reported daily and weekly by the National Institute for Communicable Diseases. The data is self-reported by healthcare facilities to the NICD’s purpose-built DatCov system. The underlying data is not available to the public. There are days missing, where data was not reported and the data is also limited by the low number of public hospitals that use the system to report Covid-19 hospitilisation data. As of mid-August 2020, 155 public hospitals out of more than 400 were reporting data on the DatCov system, as opposed to 100% of private hospitals.</p>',
      sources: [
        {
          title: 'NICD',
          link:
            'https://www.nicd.ac.za/diseases-a-z-index/covid-19/surveillance-reports/',
        },
      ],
    },

    {
      display_title: 'Provincial Overviews',
      image_url: './images/graphs-and-maps/provincial-overview.png',
      content:
        '<p>Health district level data is only reported for Gauteng and the Western Cape, and only total cases and recoveries are reported at sub-district level.</p><p>Reporting of district level Covid-19 data in the Eastern Cape has been sporadic and unreliable. Data for Gauteng is reported daily, delayed by one day, via a media WhatsApp group and via the Gauteng health department’s <a href="https://twitter.com/GautengHealth" target="_blank" rel="noopener noreferrer">Twitter account</a>.</p>',
      sources: [
        {
          title: 'Western Cape',
          link: 'https://coronavirus.westerncape.gov.za/news/',
        },
        {
          title: 'Gauteng',
          link: 'https://www.gauteng.gov.za/Home',
        },
      ],
    },
  ]

  const renderSourceLink = (link): JSX.Element => (
    <a
      href={link.link}
      title={link.title}
      key={link.title}
      target="_blank"
      rel="noopener noreferrer"
    >
      {link.title}
    </a>
  )

  const renderContentSection = (item): JSX.Element => (
    <div key={generateReactKey()}>
      {prop('display_title', item) && <h3>{prop('display_title', item)}</h3>}
      <div className={styles.aboutTheDataContentWrapper}>
        {prop('image_url', item) && (
          <div className={styles.imageWrapper}>
            <img
              src={prop('image_url', item)}
              alt={`${prop('display_title', item)} graph example`}
            />
          </div>
        )}
        <div>
          <div
            className={styles.aboutTheDataContent}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(prop('content', item)),
            }}
          />
          {item.sources && (
            <div className={styles.sources}>
              <h6>Data Source{item.sources.length > 1 ? 's' : ''}:</h6>
              {map(renderSourceLink, item.sources)}
            </div>
          )}
        </div>
      </div>
    </div>
  )

  return (
    <div className="container">
      <Helmet>
        <title>{`${t('siteTitle')} | ${t('About the data')}`}</title>
      </Helmet>
      <AdsProvider>
        <AdSlot section={AdSections.ABOUT} name={AdNames.LB1} />
        <AdSlot
          section={AdSections.ABOUT}
          name={AdNames.STICKY}
          platform={AdPlatforms.MOB_WEB}
        />
        <section className={styles.aboutTheDataSection}>
          <h1>About the data</h1>
          {map(renderContentSection, content)}
        </section>
      </AdsProvider>
    </div>
  )
}

export default AboutTheData
