import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { assoc } from 'ramda'
import { MapState } from './types'

//---------------------------------
// actions
//---------------------------------

export const UPDATE_MAP_AVAILABILITY = '[app] set map availability'
export const updateMapAvailability = (payload: string): AnyAction => ({
  type: UPDATE_MAP_AVAILABILITY,
  payload,
})

//---------------------------------
// reducers
//---------------------------------

const overwriteMapAvailability = (state: MapState, { payload }): MapState =>
  assoc(payload, true, state)

//---------------------------------
// reducer
//---------------------------------

export const INITIAL_STATE = {
  district: false,
  province: false,
  national: false,
}

const reducer = createReducer(INITIAL_STATE, {
  [UPDATE_MAP_AVAILABILITY]: overwriteMapAvailability,
})

export default reducer
