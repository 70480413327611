import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

//---------------------------------
// actions
//---------------------------------

export const API_LOAD_DISTRICT_PAGE_DATA = '[district] fetch page content'
export const loadDistrictPageData = (payload): AnyAction => ({
  type: API_LOAD_DISTRICT_PAGE_DATA,
  payload,
})

export const API_LOAD_DISTRICT_PAGE_DATA_SUCCESS =
  '[district] fetch page content success'
export const loadDistrictPageDataSuccess = (payload): AnyAction => ({
  type: API_LOAD_DISTRICT_PAGE_DATA_SUCCESS,
  payload,
})
export const SET_DISTRICT_PAGE_CONTENT = '[district] set page content'
export const setPageContent = (payload): AnyAction => ({
  type: SET_DISTRICT_PAGE_CONTENT,
  payload,
})

export const SET_DISTRICT_STATISTICS_DATA = '[district] set statistics data'
export const setStatisticsData = (payload): AnyAction => ({
  type: SET_DISTRICT_STATISTICS_DATA,
  payload,
})

export const API_LOAD_DISTRICT_PAGE_DATA_FAILURE =
  '[district] set page content failure'
export const loadPageDataFailure = (): AnyAction => ({
  type: API_LOAD_DISTRICT_PAGE_DATA_FAILURE,
})

//---------------------------------
// reducers
//---------------------------------

const overwriteContent = (state, { payload }): any =>
  assoc('content', payload, state)

const overwriteStatistics = (state, { payload }): any =>
  assoc('statistics', payload, state)

//---------------------------------
// reducer
//---------------------------------

export const INITIAL_STATE = {
  content: {},
  statistics: {
    latest: {},
    caseHistory: {
      confirmed: {},
      recoveries: {},
    },
    movingAverage: {
      confirmed: {},
    },
  },
}

export const reducer = createReducer(INITIAL_STATE as any, {
  [SET_DISTRICT_PAGE_CONTENT]: overwriteContent,
  [SET_DISTRICT_STATISTICS_DATA]: overwriteStatistics,
})
