import React, { FunctionComponent } from 'react'
import { WithTranslation } from 'react-i18next'
import { map } from 'ramda'

import styles from './Footer.module.scss'
import Facebook from '../../common/components/Icons/Facebook'
import Twitter from '../../common/components/Icons/Twitter'
import Instagram from '../../common/components/Icons/Instagram'
import { generateReactKey } from '../../common/utils/hooks.utils'

const Footer: FunctionComponent<WithTranslation> = ({ t, i18n }) => {
  const renderFooterLinks = (link): JSX.Element => (
    <a
      key={generateReactKey()}
      href={link.path}
      target="_blank"
      rel="noopener noreferrer"
      title={link.title}
      className={styles.footerLink}
    >
      {t(link.title)}
    </a>
  )

  const footerLinks = t('footerLinks', { returnObjects: true })

  return (
    <div className={`${styles.footer} container`}>
      <h6>{t('In Partnership')}</h6>
      {i18n.language === 'en' && (
        <img
          className={styles.truthFirstLogo}
          src="/images/truth-first-logo.svg"
          alt="Truth First"
        />
      )}
      {i18n.language === 'af' && (
        <img
          className={styles.waarheidEersteLogo}
          src="/images/waarheid-eerste-logo.svg"
          alt="Waarheid eerste"
        />
      )}
      <p>{t('Truth First blurb')}</p>

      <div className={styles.footerLinks}>
        {map(renderFooterLinks, footerLinks)}
      </div>

      <div className={styles.socialLinksWrapper}>
        <p className={styles.socialLinksHeading}>{t('Follow')}:</p>
        <a
          href={t('FacebookLink')}
          target="_blank"
          rel="noopener noreferrer"
          title={t('Follow us on Facebook')}
          className={styles.socialLink}
        >
          <Facebook />
        </a>
        <a
          href={t('TwitterLink')}
          target="_blank"
          rel="noopener noreferrer"
          title={t('Follow us on Twitter')}
          className={styles.socialLink}
        >
          <Twitter />
        </a>
        <a
          href={t('InstagramLink')}
          target="_blank"
          rel="noopener noreferrer"
          title={t('Follow us on Instagram')}
          className={styles.socialLink}
        >
          <Instagram />
        </a>
      </div>

      <img
        className={styles.iabLogo}
        src={require('../../assets/images/iab-logo.png')}
        alt="iab logo"
      />
    </div>
  )
}

export default Footer
