import { createSelector } from 'reselect'
import { path, compose, keys, values, fromPairs } from 'ramda'
import { getProvinceCodeFromPath } from '../../common/utils/data'
import { RootState } from '../../common/redux/types'

export const getCurrentPageProvinceCode = (provincePaths) =>
  compose(
    getProvinceCodeFromPath(provincePaths),
    path(['router', 'location', 'pathname']),
  )

export const getCurrentPageProvinceCodeSelector = (provincePaths) =>
  createSelector(
    [getCurrentPageProvinceCode(provincePaths)],
    (content) => content,
  )

export const getMainContent = (state: RootState) =>
  path(['province', 'content', 'highlights'], state)
export const getMainContentSelector = createSelector(
  [getMainContent],
  (content) => content,
)

export const getDailyCasesContent = path(['province', 'content', 'daily_cases'])
export const getDailyCasesContentSelector = createSelector(
  [getDailyCasesContent],
  (content) => content,
)

export const getProvincialCasesContent = path([
  'province',
  'content',
  'cases_overview',
])
export const getProvincialCasesContentSelector = createSelector(
  [getProvincialCasesContent],
  (content) => content,
)

export const getDeathsContent = path(['province', 'content', 'deaths_overview'])
export const getDeathsContentSelector = createSelector(
  [getDeathsContent],
  (content) => content,
)

export const getHospitalisationsContent = path([
  'province',
  'content',
  'hospitalisations',
])
export const getHospitalisationsContentSelector = createSelector(
  [getHospitalisationsContent],
  (content) => content,
)

export const getDailyChanges = path(['province', 'statistics', 'dailyChanges'])
export const getDailyChangesSelector = createSelector(
  [getDailyChanges],
  (content) => content,
)

export const getAdmissions = compose(
  values,
  fromPairs,
  path(['province', 'statistics', 'hospitalisations', 'currentlyAdmitted']),
)
export const getAdmissionsSelector = createSelector(
  [getAdmissions],
  (content) => content,
)

export const getIcuCases = compose(
  values,
  fromPairs,
  path(['province', 'statistics', 'hospitalisations', 'icu']),
)
export const getIcuCasesSelector = createSelector(
  [getIcuCases],
  (content) => content,
)

export const getVentilatorCases = compose(
  values,
  fromPairs,
  path(['province', 'statistics', 'hospitalisations', 'ventilated']),
)
export const getVentilatorCasesSelector = createSelector(
  [getVentilatorCases],
  (content) => content,
)

export const getProvincialDeaths = (provincePaths) => (state: RootState) => {
  const provinceCode = getCurrentPageProvinceCode(provincePaths)(state)

  const cases = path(
    ['province', 'statistics', 'caseHistory', 'deaths', provinceCode],
    state,
  )

  return compose(values, fromPairs)(cases || {})
}
export const getProvincialDeathsSelector = createSelector(
  [getProvincialDeaths],
  (content) => content,
)

export const getProvincialRecoveries = (provincePaths) => (
  state: RootState,
) => {
  const provinceCode = getCurrentPageProvinceCode(provincePaths)(state)
  const cases = path(
    ['province', 'statistics', 'caseHistory', 'recoveries', provinceCode],
    state,
  )

  return compose(values, fromPairs)(cases || {})
}
export const getProvincialRecoveriesSelector = createSelector(
  [getProvincialRecoveries],
  (content) => content,
)

export const getProvincialActiveCases = (provincePaths) => (
  state: RootState,
) => {
  const provinceCode = getCurrentPageProvinceCode(provincePaths)(state)
  const cases = path(
    ['province', 'statistics', 'caseHistory', 'active', provinceCode],
    state,
  )

  return compose(values, fromPairs)(cases || {})
}

export const getProvincialActiveCasesSelector = createSelector(
  [getProvincialActiveCases],
  (content) => content,
)

export const getProvincialDailyCases = (provincePaths) => (
  state: RootState,
) => {
  const provinceCode = getCurrentPageProvinceCode(provincePaths)(state)
  const cases = path(
    [
      'province',
      'statistics',
      'caseHistory',
      'confirmed',
      `daily${provinceCode}`,
    ],
    state,
  )

  return compose(values, fromPairs)(cases || {})
}

export const getProvincialDailyCasesSelector = createSelector(
  [getProvincialDailyCases],
  (content) => content,
)

export const getProvincialDailyCasesDates = (provincePaths) => (
  state: RootState,
) => {
  const provinceCode = getCurrentPageProvinceCode(provincePaths)(state)
  const cases = path(
    [
      'province',
      'statistics',
      'caseHistory',
      'confirmed',
      `daily${provinceCode}`,
    ],
    state,
  )

  return compose(keys, fromPairs)(cases || {})
}

export const getProvincialDailyCasesDatesSelector = createSelector(
  [getProvincialDailyCasesDates],
  (content) => content,
)

export const getNationalDeaths = compose(
  values,
  fromPairs,
  path(['province', 'statistics', 'caseHistory', 'deaths', 'ZA']),
)
export const getNationalDeathsSelector = createSelector(
  [getNationalDeaths],
  (content) => content,
)

export const getStatisticsDates = (state: RootState) => {
  const cases = path(
    ['province', 'statistics', 'caseHistory', 'active', 'ZA'],
    state,
  )

  return compose(keys, fromPairs)(cases)
}
export const getStatisticsDatesSelector = createSelector(
  [getStatisticsDates],
  (content) => content,
)

export const getHospitalisationsDates = compose(
  keys,
  fromPairs,
  path(['province', 'statistics', 'hospitalisations', 'currentlyAdmitted']),
)

export const getHospitalisationsDatesSelector = createSelector(
  [getHospitalisationsDates],
  (content) => content,
)

export const getLatestProvincialStatistics = path([
  'province',
  'statistics',
  'latestProvincialData',
])
export const getLatestProvincialStatisticsSelector = createSelector(
  [getLatestProvincialStatistics],
  (content) => content,
)

export const getLatestNationalStatistics = path([
  'province',
  'statistics',
  'latestNationalData',
])
export const getLatestNationalStatisticsSelector = createSelector(
  [getLatestNationalStatistics],
  (content) => content,
)

export const getDailyCasesMovingAverage = compose(
  values,
  fromPairs,
  path(['province', 'statistics', 'movingAverage', 'confirmed']),
)

export const getDailyCasesMovingAverageSelector = createSelector(
  [getDailyCasesMovingAverage],
  (content) => content,
)
