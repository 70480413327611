import { createSelector } from 'reselect'
import { compose, map, path, fromPairs, values, keys, prop } from 'ramda'
import { RootState } from '../../common/redux/types'

export const getMainContent = (state: RootState) =>
  path(['country', 'content', 'highlights'], state)
export const getMainContentSelector = createSelector(
  [getMainContent],
  (content) => content,
)

export const getDailyCasesContent = (state: RootState) =>
  path(['country', 'content', 'daily_cases'], state)
export const getDailyCasesContentSelector = createSelector(
  [getDailyCasesContent],
  (content) => content,
)

export const getNationalCasesContent = (state: RootState) =>
  path(['country', 'content', 'cases_overview'], state)
export const getNationalCasesContentSelector = createSelector(
  [getNationalCasesContent],
  (content) => content,
)

export const getDeathsOverviewContent = (state: RootState) =>
  path(['country', 'content', 'deaths_overview'], state)
export const getDeathsOverviewContentSelector = createSelector(
  [getDeathsOverviewContent],
  (content) => content,
)

export const getNationalTestingContent = (state: RootState) =>
  path(['country', 'content', 'national_testing'], state)
export const getNationalTestingContentSelector = createSelector(
  [getNationalTestingContent],
  (content) => content,
)

export const getHospitalisationsContent = (state: RootState) =>
  path(['country', 'content', 'hospitalisations'], state)
export const getHospitalisationsContentSelector = createSelector(
  [getHospitalisationsContent],
  (content) => content,
)

export const getProvincialComparisonsContent = (state: RootState) =>
  path(['country', 'content', 'provincial_comparisons'], state)
export const getProvincialComparisonsContentSelector = createSelector(
  [getProvincialComparisonsContent],
  (content) => content,
)

export const getLatestStatistics = (state: RootState) =>
  path(['country', 'statistics', 'latest'], state)
export const getLatestStatisticsSelector = createSelector(
  [getLatestStatistics],
  (content) => content,
)

export const getDailyChanges = (state: RootState) =>
  path(['country', 'statistics', 'dailyChanges'], state)
export const getDailyChangesSelector = createSelector(
  [getDailyChanges],
  (content) => content,
)

export const getConfirmedCasesByProvince = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'caseHistory', 'confirmed'],
    state,
  )
  const getValues = compose(values, fromPairs)

  return map(getValues)(cases)
}
export const getConfirmedCasesByProvinceSelector = createSelector(
  [getConfirmedCasesByProvince],
  (content) => content,
)

export const getConfirmedCasesByProvinceDates = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'caseHistory', 'confirmed'],
    state,
  )
  const getKeys = compose(keys, fromPairs)

  return map(getKeys)(cases)
}
export const getConfirmedCasesByProvinceDatesSelector = createSelector(
  [getConfirmedCasesByProvinceDates],
  (content) => content,
)

export const getConfirmedCasesMovingAverage = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'movingAverage', 'confirmed'],
    state,
  )

  return compose(values, fromPairs)(cases)
}
export const getConfirmedCasesMovingAverageSelector = createSelector(
  [getConfirmedCasesMovingAverage],
  (content) => content,
)

export const getStatisticsDates = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'caseHistory', 'active', 'ZA'],
    state,
  )

  return compose(keys, fromPairs)(cases)
}
export const getStatisticsDatesSelector = createSelector(
  [getStatisticsDates],
  (content) => content,
)

export const getDailyCasesDates = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'caseHistory', 'confirmed', 'dailyZA'],
    state,
  )

  return compose(keys, fromPairs)(cases)
}
export const getDailyCasesDatesSelector = createSelector(
  [getDailyCasesDates],
  (content) => content,
)

export const getNationalDeaths = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'caseHistory', 'deaths', 'ZA'],
    state,
  )

  return compose(values, fromPairs)(cases)
}
export const getNationalDeathsSelector = createSelector(
  [getNationalDeaths],
  (content) => content,
)

export const getNationalRecoveries = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'caseHistory', 'recoveries', 'ZA'],
    state,
  )

  return compose(values, fromPairs)(cases)
}
export const getNationalRecoveriesSelector = createSelector(
  [getNationalRecoveries],
  (content) => content,
)

export const getNationalActiveCases = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'caseHistory', 'active', 'ZA'],
    state,
  )

  return compose(values, fromPairs)(cases)
}
export const getNationalActiveCasesSelector = createSelector(
  [getNationalActiveCases],
  (content) => content,
)

export const getDeathsPerProvince = (state: RootState) => {
  const cases = path(['country', 'statistics', 'caseHistory', 'deaths'], state)
  const getValues = compose(values, fromPairs)

  return map(getValues)(cases)
}
export const getDeathsPerProvinceSelector = createSelector(
  [getDeathsPerProvince],
  (content) => content,
)

export const getAdmissions = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'hospitalisations', 'currentlyAdmitted', 'ZA'],
    state,
  )

  return compose(values, fromPairs)(cases)
}
export const getAdmissionsSelector = createSelector(
  [getAdmissions],
  (content) => content,
)

export const getIcuCases = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'hospitalisations', 'icu', 'ZA'],
    state,
  )

  return compose(values, fromPairs)(cases)
}
export const getIcuCasesSelector = createSelector(
  [getIcuCases],
  (content) => content,
)

export const getVentilatorCases = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'hospitalisations', 'ventilated', 'ZA'],
    state,
  )

  return compose(values, fromPairs)(cases)
}
export const getVentilatorCasesSelector = createSelector(
  [getVentilatorCases],
  (content) => content,
)

export const getPublicTestingStats = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'testing', 'public', 'dailyZA'],
    state,
  )

  return compose(values, fromPairs)(cases)
}
export const getPublicTestingStatsSelector = createSelector(
  [getPublicTestingStats],
  (content) => content,
)
export const getPrivateTestingStats = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'testing', 'private', 'dailyZA'],
    state,
  )

  return compose(values, fromPairs)(cases)
}
export const getPrivateTestingStatsSelector = createSelector(
  [getPrivateTestingStats],
  (content) => content,
)

export const getDailyTestMovingAverage = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'testing', 'dailyTestMovingAverage'],
    state,
  )

  return compose(values, fromPairs)(cases)
}
export const getDailyTestMovingAverageSelector = createSelector(
  [getDailyTestMovingAverage],
  (content) => content,
)

export const getDailyPositiveCases = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'testing', 'dailyPositive', 'positiveTestData'],
    state,
  )

  const positiveCases = map((stat) => {
    return +(prop('positivePercentage', stat) * 100).toFixed(2)
  }, cases)

  return positiveCases
}
export const getDailyPositiveCasesSelector = createSelector(
  [getDailyPositiveCases],
  (content) => content,
)

export const getHospitalisationsDates = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'hospitalisations', 'currentlyAdmitted', 'ZA'],
    state,
  )

  return compose(keys, fromPairs)(cases)
}
export const getHospitalisationsDatesSelector = createSelector(
  [getHospitalisationsDates],
  (content) => content,
)

export const getTestingDates = (state: RootState) => {
  const cases = path(
    ['country', 'statistics', 'testing', 'dailyTestMovingAverage'],
    state,
  )

  return compose(keys, fromPairs)(cases)
}
export const getTestingDatesSelector = createSelector(
  [getTestingDates],
  (content) => content,
)

export const getPositiveTestDates = (state: RootState) =>
  path(
    ['country', 'statistics', 'testing', 'dailyPositive', 'positiveTestDates'],
    state,
  )
export const getPositiveTestDatesSelector = createSelector(
  [getPositiveTestDates],
  (content) => content,
)
