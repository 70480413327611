import { createSelector } from 'reselect'
import { applySpec, map, prop, path } from 'ramda'

import { getAllButFirstLatestArticlesSelector } from '../NewsArticles/NewsArticles.selector'

const selector = createSelector(
  [getAllButFirstLatestArticlesSelector],
  applySpec({
    articles: map(
      applySpec({
        date: prop('displayDate'),
        title: prop('title'),
        image: path(['thumbnails', 'medium']),
        link: prop('articleUrl'),
      }),
    ),
  }),
)

export default selector
