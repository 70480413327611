import React from 'react'

import { IconProps } from './types'

const Facebook = (props: IconProps): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    width={props.width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || '#000'}
      d="M23.16 12.07a11.16 11.16 0 10-12.9 11v-7.8H7.42v-3.2h2.84V9.61c0-2.8 1.66-4.34 4.21-4.34a17.86 17.86 0 012.5.21v2.75h-1.41A1.62 1.62 0 0013.74 10v2.1h3.1l-.5 3.22h-2.6v7.8a11.16 11.16 0 009.42-11.05z"
    />
  </svg>
)

export default Facebook
