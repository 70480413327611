import { AnyAction } from '@reduxjs/toolkit'

import {
  API_LOAD_COUNTRY_PAGE_DATA,
  API_LOAD_COUNTRY_PAGE_DATA_SUCCESS,
  loadCountryPageDataSuccess,
  setPageContent,
  setStatisticsData,
  loadPageDataFailure,
} from './country.reducer'
import { dispatch as DispatchType } from '../../common/redux/types'
import axios from 'axios'

//---------------------------------
// set district map data
//---------------------------------

export const loadCountryPageDataFlow = ({ dispatch }: DispatchType) => (
  next: (action: AnyAction) => void,
) => (action: AnyAction): void => {
  next(action)

  const { type, payload } = action

  if (type === API_LOAD_COUNTRY_PAGE_DATA) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${payload.dataType}/${payload.pageToLoad}`,
      )
      .then((response) =>
        dispatch(
          loadCountryPageDataSuccess({
            name: payload.dataType,
            data: response.data,
          }),
        ),
      )
      .catch(loadPageDataFailure)
  }
}

export const loadCountryPageDataSuccessFlow = ({ dispatch }: DispatchType) => (
  next: (action: AnyAction) => void,
) => (action: AnyAction): void => {
  next(action)

  const { type, payload } = action

  if (type === API_LOAD_COUNTRY_PAGE_DATA_SUCCESS) {
    if (payload.name === 'content') dispatch(setPageContent(payload.data))
    if (payload.name === 'statistics') dispatch(setStatisticsData(payload.data))
  }
}
