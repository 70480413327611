import { AdNames, AdSizeOptions, AdSizes } from './Ads.types'

export const adSizes: AdSizes = {
  [AdSizeOptions.SIZE_728_X_90]: [[728, 90]],
  [AdSizeOptions.SIZE_300_X_250]: [[300, 250]],
  [AdSizeOptions.SIZE_320_X_50]: [[320, 50]],
}

// Worried about circular dependencies, so putting these here...
type AdSizeKeys = keyof typeof adSizes
type AdSizeValues = typeof adSizes[AdSizeKeys]
export type AdNameSizes = Record<AdNames, AdSizeValues>

export const adNameSizes: AdNameSizes = {
  [AdNames.LB1]: adSizes[AdSizeOptions.SIZE_728_X_90],
  [AdNames.LB2]: adSizes[AdSizeOptions.SIZE_728_X_90],
  [AdNames.LB3]: adSizes[AdSizeOptions.SIZE_728_X_90],
  [AdNames.MREC1]: adSizes[AdSizeOptions.SIZE_300_X_250],
  [AdNames.MREC2]: adSizes[AdSizeOptions.SIZE_300_X_250],
  [AdNames.MREC3]: adSizes[AdSizeOptions.SIZE_300_X_250],
  [AdNames.STICKY]: adSizes[AdSizeOptions.SIZE_320_X_50],
}

export const ADS_MOBILE_CLASS_NAME = 'mobile-ad'
export const ADS_DESKTOP_CLASS_NAME = 'desktop-ad'
export const SET_TARGETING_ADNAME = 'adname'
