import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { debounce } from './functions'

export interface WindowSize {
  width?: number
  height?: number
}

export const useWindowSize = (): WindowSize => {
  // via: https://usehooks.com/useWindowSize/
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  const initialState: WindowSize = {
    width: undefined,
    height: undefined,
  }
  const [windowSize, setWindowSize] = useState(initialState)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', () => debounce(handleResize, 200), false)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export const generateReactKey = (): string => uuidv4()
