import React from 'react'

import { IconProps } from './types'

const Clock = (props: IconProps): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    width={props.width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || '#000'}
      d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16.001A8 8 0 0112 20zm3.1-7.37L13 11.42V7a1 1 0 00-2 0v5.12a.65.65 0 00.05.2c.02.06.047.116.08.17.027.057.06.11.1.16l.16.13.09.09 2.6 1.5a1 1 0 00.5.13 1 1 0 00.5-1.87h.02z"
    />
  </svg>
)

export default Clock
