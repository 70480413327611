import React, { SFC, useEffect } from 'react'
import ZingChart from 'zingchart-react'

import { AvailableMaps } from '../../../App/types'

interface Props {
  onLoadGeoJson: (mapName) => void
  mapName: AvailableMaps
  isAvailable: boolean
  mapOptions?: any
  shapeClick?: (shape: string) => void
}

const Map: SFC<Props> = ({
  onLoadGeoJson,
  mapName,
  isAvailable,
  mapOptions,
  shapeClick,
}) => {
  const mapData = {
    gui: {
      contextMenu: {
        empty: true,
      },
    },
    shapes: [
      {
        type: 'zingchart.maps',
        options: {
          zooming: false,
          panning: false,
          scrolling: false,

          name: mapName,
          style: {
            controls: {
              visible: false,
            },
            label: {
              visible: false,
            },
            ...mapOptions,
          },
        },
      },
    ],
  }

  useEffect(() => {
    if (mapName) {
      onLoadGeoJson(mapName)
    }
  }, [onLoadGeoJson, mapName])

  return (
    <>
      {isAvailable ? (
        <>
          {shapeClick ? (
            <ZingChart data={mapData} shape_click={shapeClick && shapeClick} />
          ) : (
            <ZingChart data={mapData} />
          )}
        </>
      ) : (
        <div className="loader">Loading...</div>
      )}
    </>
  )
}

export default Map
