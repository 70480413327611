import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import SubDistrict from './SubDistrict.component'
import {
  getMainContentSelector,
  getCurrentPageDistrictPath,
  getDailyCasesMovingAverage,
  getConfirmedCasesByDistrict,
  getRecoveriesByDistrict,
  getLatestStatistics,
  getDailyChanges,
} from './subDistrict.selectors'
import { loadSubDistrictPageData } from './subDistrict.reducer'

const mapStateToProps = (state) => ({
  districtPath: getCurrentPageDistrictPath(state),
  mainContent: getMainContentSelector(state),
  confirmedCasesByDistrict: getConfirmedCasesByDistrict(state),
  recoveriesByDistrict: getRecoveriesByDistrict(state),
  confirmedCasesMovingAverage: getDailyCasesMovingAverage(state),
  latestStatistics: getLatestStatistics(state),
  dailyChanges: getDailyChanges(state),
})

const mapDispatchToProps = (dispatch) => ({
  onSelectSubDistrict: (payload) => dispatch(loadSubDistrictPageData(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SubDistrict))
