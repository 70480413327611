import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from 'react'
import { filter, isNil } from 'ramda'

import { adNameSizes, SET_TARGETING_ADNAME } from './Ads.constants'
import {
  buildAdPath,
  getIsMobile,
  getIsMobileWebAdSlot,
  getIsWebAdSlot,
} from './Ads.utils'
import { AdSlot } from './Ads.types'
import { useWindowSize } from '../../common/utils/hooks.utils'

declare let googletag: any

type AdSlotContextProps = {
  addAdSlot: (slot: AdSlot) => void
}
export const AdSlotContext = createContext<Partial<AdSlotContextProps>>({})

const AdsProvider: FunctionComponent = ({ children }) => {
  const windowSizes = useWindowSize()

  const [isMobile, setIsMobile] = useState(undefined as undefined | boolean)
  const [adSlots, setAdSlots] = useState([] as AdSlot[])
  const [adSlotsRaw, setAdSlotsRaw] = useState([] as AdSlot[])
  const [adSlotsWeb, setAdSlotsWeb] = useState([] as AdSlot[])
  const [adSlotsMobWeb, setAdSlotsMobWeb] = useState([] as AdSlot[])
  const [isCompleteInit, setIsCompleteInit] = useState(
    undefined as boolean | undefined,
  )

  const _slots: AdSlot[] = []

  const value: AdSlotContextProps = {
    addAdSlot: (slot) => {
      _slots.push(slot)
    },
  }

  useEffect(() => {
    if (_slots.length > 0) {
      setAdSlotsRaw(_slots)
    }
  }, [_slots])

  // TODO: Cleanup these hooks by moving into Ads.hooks module
  useEffect(() => {
    if (adSlotsRaw.length > 0) {
      const slotsWeb = filter(
        ({ platform }) => getIsWebAdSlot(platform),
        adSlotsRaw,
      )
      const slotsMobWeb = filter(
        ({ platform }) => getIsMobileWebAdSlot(platform),
        adSlotsRaw,
      )
      setAdSlotsWeb(slotsWeb)
      setAdSlotsMobWeb(slotsMobWeb)
    }
  }, [adSlotsRaw])

  useEffect(() => {
    if (!isNil(windowSizes.width)) {
      setIsMobile(getIsMobile(windowSizes))
    }
  }, [windowSizes])

  useEffect(() => {
    if (
      !isNil(isMobile) &&
      (adSlotsWeb.length > 0 || adSlotsMobWeb.length > 0)
    ) {
      const _adSlots = isMobile ? adSlotsMobWeb : adSlotsWeb
      setAdSlots(_adSlots)
    }
  }, [adSlotsWeb, adSlotsMobWeb, isMobile])
  ;(window as any).googletag = (window as any).googletag || { cmd: [] }
  googletag.cmd = googletag.cmd || []

  useEffect(() => {
    if (adSlots.length > 0) {
      googletag.cmd.push(function () {
        adSlots.forEach((slot) => {
          const path = buildAdPath(slot.section, slot.name, slot.platform)
          const size = adNameSizes[slot.name]
          return googletag
            .defineSlot(path, size, slot.name)
            .setTargeting(SET_TARGETING_ADNAME, [slot.name.toLowerCase()])
            .addService(googletag.pubads())
        })
        googletag.pubads().collapseEmptyDivs()
        googletag.pubads().setCentering(true)
        googletag.pubads().enableSingleRequest()
        googletag.enableServices()
        setIsCompleteInit(true)
      })
    }
    return () => {
      googletag.destroySlots && googletag.destroySlots()
    }
  }, [adSlots])

  useEffect(() => {
    if (adSlots.length > 0 && isCompleteInit) {
      adSlots.forEach((slot) => {
        googletag.cmd.push(function () {
          googletag.display(slot.name)
        })
      })
    }
  }, [adSlots, isCompleteInit])

  return (
    <AdSlotContext.Provider value={value}>{children}</AdSlotContext.Provider>
  )
}

export default AdsProvider
