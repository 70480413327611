import React, { FunctionComponent } from 'react'

interface Props {
  condition: any
  Fallback?: any
  children: any
}

const Optional: FunctionComponent<Props> = ({
  condition,
  Fallback = null,
  children,
}) => {
  return <>{condition ? children : Fallback}</>
}

export default Optional
