import React, { FC } from 'react'
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  SwiperOptions,
} from 'swiper'
import { Swiper } from 'swiper/react'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Autoplay, Navigation, Pagination])

const SwiperInit: FC<SwiperOptions & { className?: string }> = ({
  children,
  ...props
}) => {
  return <Swiper {...props}>{children}</Swiper>
}

export default SwiperInit
