import React, { FunctionComponent } from 'react'
import {
  compose,
  mapObjIndexed,
  path,
  prop,
  filter,
  keys,
  map,
  values,
  toPairs,
  head,
  last,
  mergeAll,
  drop,
} from 'ramda'
import { Helmet } from 'react-helmet'
import { WithTranslation } from 'react-i18next'

import MainContent from '../MainContent'
import AdsProvider, { AdSlot, AdNames, AdPlatforms, AdSections } from '../Ads'
import { MainContentSection, PageContentSection } from '../Country/types'
import Line from '../../common/components/Charts/Line'
import StackedBarAndLine from '../../common/components/Charts/StackedBarAndLine'
import ContentSection from '../ContentSection'
import Tickers from '../Tickers'
import {
  getRegionName,
  createPercentageString,
  getPrettyDate,
  getSubDistrictChartValues,
} from '../../common/utils/data'
import { formatNumberWithSpaces } from '../../common/utils/functions'
import Optional from '../../common/components/Optional'
import LoadUntilReady from '../../common/components/LoadUntilReady'
import BarChart from '../../common/components/Charts/BarChart'
import { DISTRICT_NAMES } from '../District/district.types'
import { Map } from '../Maps/components/Map'
import { SubDistrict } from '../SubDistrict'
import FeatureArticle from '../FeatureArticle'
import RelatedArticles from '../RelatedArticles'
import MostReadArticles from '../MostReadArticles'

interface Props {
  districtCode: string
  mainContent: MainContentSection
  dailyCasesContent: PageContentSection
  casesOverview: PageContentSection
  casesBySubDistrictContent: PageContentSection
  dailyCasesDates: number[]
  confirmedCasesByDistrict: number[]
  confirmedCasesMovingAverage: number[]
  recoveriesByDistrict: number[]
  dailyChanges: number[]
  latestStatistics: number[]
}

const District: FunctionComponent<Props & WithTranslation> = ({
  t,
  districtCode,
  mainContent,
  dailyCasesContent,
  casesOverview,
  casesBySubDistrictContent,
  dailyCasesDates,
  confirmedCasesByDistrict,
  confirmedCasesMovingAverage,
  recoveriesByDistrict,
  dailyChanges,
  latestStatistics,
}) => {
  const currentDistrictName = getRegionName(districtCode, DISTRICT_NAMES)

  const generateMapConfigItem = (currentValue) => ({
    'data-title': t(head(currentValue)),
    'data-date': getPrettyDate(prop('date', last(currentValue))),
    'data-total-cases': formatNumberWithSpaces(
      prop('confirmed', last(currentValue)),
    ),
    'data-recovered': formatNumberWithSpaces(
      prop('recoveries', last(currentValue)),
    ),
    'backgroundColor': '#dd1f26',
  })

  const getDistrictMapInfo = compose(
    mergeAll,
    map((currentValue) => ({
      [head(currentValue).replace(/ /g, '')]: generateMapConfigItem(
        currentValue,
      ),
    })),
    toPairs,
  )

  const mapOptions = {
    zooming: false,
    panning: false,
    scrolling: false,
    tooltip: {
      'callout': true,
      'text': `<div class="tooltip"><div class="title"><h6>%data-title</h6><span>%data-date</span></div><div class="stat">Total Cases <div class="amount">%data-total-cases</div></div><div class="stat">Recovered<div class="amount">%data-recovered</div></div></div>`,
      'html-mode': true,
      'backgroundColor': '#fff',
      'borderRadius': '4px',
      'padding': '0',
      'fontColor': '#212121',
      'borderColor': '#f5f5f5',
      'shadow': 'false',
      'font-family': '"Proxima Nova", sans-serif',
    },
    items: getDistrictMapInfo(latestStatistics),
  }

  const tickerData = [
    {
      title: 'Total Cases',
      amount: path([currentDistrictName, 'confirmed'], latestStatistics),
      movement: createPercentageString(
        prop('confirmedPercentage', dailyChanges),
      ),
      movementDirection: path(['confirmedPercentage'], dailyChanges) > 0,
      movementPositive: path(['confirmedPercentage'], dailyChanges) < 0,
    },
    {
      title: 'New Cases',
      amount: prop('newCases', dailyChanges),
      movement: '',
      movementDirection: false,
      movementPositive: false,
    },
    {
      title: 'Recoveries',
      amount: path([currentDistrictName, 'recoveries'], latestStatistics),
      movement: createPercentageString(
        prop('recoveriesPercentage', dailyChanges),
      ),
      movementDirection: prop('recoveriesPercentage', dailyChanges) > 0,
      movementPositive: prop('recoveriesPercentage', dailyChanges) > 0,
    },
  ]

  const dailyCasesGraphConfig = {
    scaleY: {
      label: {
        text: 'Number of cases',
      },
      short: true,
    },
    scaleX: {
      label: {
        text: 'Date',
      },
      values: prop(`daily${currentDistrictName}`, dailyCasesDates),
      step: 'day',
      transform: {
        type: 'date',
        all: '%M %d',
      },
    },
    plot: { stacked: false },
    series: [
      {
        text: `${currentDistrictName} cases`,
        backgroundColor: '#4973D5',
        border: '1px solid #fff',
        type: 'bar',
        values: prop(`daily${currentDistrictName}`, confirmedCasesByDistrict),
      },
      {
        type: 'line',
        text: '7-Day Moving average',
        values: drop(1)(confirmedCasesMovingAverage),
        lineColor: '#21418D',
      },
    ],
  }

  const casesOverviewGraphConfig = {
    scaleY: {
      label: {
        text: 'Total Cases',
      },
      short: true,
    },
    scaleX: {
      label: {
        text: 'Date',
      },
      values: prop(currentDistrictName, dailyCasesDates),
      step: 'day',
      transform: {
        type: 'date',
        all: '%M %d',
      },
    },
    series: [
      {
        text: 'Confirmed cases',
        lineColor: '#21418D',
        values: prop(currentDistrictName, confirmedCasesByDistrict),
      },
      {
        text: 'Recoveries',
        lineColor: '#7796DF',
        values: prop(currentDistrictName, recoveriesByDistrict),
      },
    ],
  }

  const subDistrictData = compose(
    filter((region) => region !== false),
    mapObjIndexed((region, key) => {
      return key !== currentDistrictName ? region : false
    }),
  )(latestStatistics)

  const casesBySubDistrictChartConfig = {
    scaleY: {
      label: {
        text: `Number of cases`,
      },
      short: true,
    },
    scaleX: {
      label: {
        text: 'Sub-district',
      },
      item: { visible: false },
    },
    tooltip: {
      text: `%plot-text total confirmed cases: %v`,
    },
    legend: {
      border: 'none',
      layout: 'float',
      align: 'center',
      textAlign: 'center',
      item: {
        fontColor: '#21211',
        fontWeight: '500',
      },
      marker: {
        borderRadius: '4px',
        height: '16px',
        width: '16px',
      },
    },
    series: getSubDistrictChartValues(subDistrictData),

    mediaRules: [
      {
        minWidth: 700,
        legend: { visible: false },
        scaleX: {
          labels: keys(subDistrictData),
          item: { visible: true },
        },
        tooltip: {
          text: `%kl total confirmed cases: %v`,
        },
        series: [
          { values: compose(map(prop('confirmed')), values)(subDistrictData) },
        ],
        plot: {
          styles: [
            '#2a51ac',
            '#68c6fa',
            '#82c459',
            '#e9d25f',
            '#f5931f',
            '#e23036',
            '#d86d9c',
            '#a177c3',
            '#211717',
          ],
        },
      },
    ],
  }

  return (
    <div className="container">
      <Helmet>
        <title>{`${t('siteTitle')} | ${t(currentDistrictName)}`}</title>
      </Helmet>
      <AdsProvider>
        <AdSlot section={AdSections.DISTRICT} name={AdNames.LB1} />
        <AdSlot
          section={AdSections.DISTRICT}
          name={AdNames.STICKY}
          platform={AdPlatforms.MOB_WEB}
        />
        <Optional condition={!!mainContent}>
          <MainContent
            title={t(`Covid-19 in ${currentDistrictName}`)}
            date={prop('last_updated_on', mainContent)}
            content={prop('content', mainContent)}
          >
            <LoadUntilReady isLoading={!latestStatistics}>
              <>
                <Map mapName={districtCode} mapOptions={mapOptions} />
                <Tickers tickerData={tickerData} />
              </>
            </LoadUntilReady>
          </MainContent>
        </Optional>
        <AdSlot section={AdSections.DISTRICT} name={AdNames.LB2} />
        <AdSlot
          section={AdSections.DISTRICT}
          name={AdNames.MREC1}
          platform={AdPlatforms.MOB_WEB}
        />
        <Optional condition={!!dailyCasesContent}>
          <ContentSection content={dailyCasesContent}>
            <LoadUntilReady isLoading={!latestStatistics}>
              <StackedBarAndLine chartData={dailyCasesGraphConfig} />
            </LoadUntilReady>
          </ContentSection>
        </Optional>
        <AdSlot section={AdSections.DISTRICT} name={AdNames.LB3} />
        <AdSlot
          section={AdSections.DISTRICT}
          name={AdNames.MREC2}
          platform={AdPlatforms.MOB_WEB}
        />
        <FeatureArticle />
        <Optional condition={!!casesOverview}>
          <ContentSection content={casesOverview}>
            <LoadUntilReady isLoading={!latestStatistics}>
              <Line chartData={casesOverviewGraphConfig} />
            </LoadUntilReady>
          </ContentSection>
        </Optional>
        <MostReadArticles />
        <Optional condition={!!casesBySubDistrictContent}>
          <ContentSection content={casesBySubDistrictContent}>
            <LoadUntilReady isLoading={!latestStatistics}>
              <BarChart chartData={casesBySubDistrictChartConfig} />
            </LoadUntilReady>
          </ContentSection>
        </Optional>
        <SubDistrict subDistrictData={subDistrictData} />
        <RelatedArticles />
        <AdSlot
          section={AdSections.DISTRICT}
          name={AdNames.MREC3}
          platform={AdPlatforms.MOB_WEB}
        />
      </AdsProvider>
    </div>
  )
}

export default District
