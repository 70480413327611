import {
  DailyChangesData,
  LatestStatistics,
  ProvincialComparisonChartStatisticsProp,
  ProvincialComparisonChartTypes,
} from './types'
import { clone, map, path, prop, propOr, pipe, __, values } from 'ramda'
import { createPercentageString } from '../../common/utils/data'
import { ProvinceChartConfig } from '../../common/components/Charts/Charts.types'
import { PROVINCE_CODES } from '../Province/Province.types'

export const getDailyCasesGraphConfig = (
  dailyCasesDates: string[],
  confirmedCasesByProvince: number[],
  confirmedCasesMovingAverage: string[],
): any => ({
  scaleY: {
    label: {
      text: 'Number of cases',
    },
    short: true,
  },
  scaleX: {
    label: {
      text: 'Date',
    },
    values: dailyCasesDates,
    step: 'day',
    transform: {
      type: 'date',
      all: '%M %d',
    },
  },
  plot: { stacked: false },
  series: [
    {
      text: 'National cases',
      backgroundColor: '#4973D5',
      border: '1px solid #fff',
      type: 'bar',
      values: prop('dailyZA', confirmedCasesByProvince),
    },
    {
      type: 'line',
      text: '7-Day Moving average',
      values: confirmedCasesMovingAverage,
      lineColor: '#21418D',
    },
  ],
})

export const getNationalCasesGraphConfig = (
  statisticsDates: string[],
  nationalActiveCases: number[],
  nationalDeaths: number[],
  nationalRecoveries: number[],
): any => ({
  scaleY: {
    label: {
      text: 'Number of cases',
    },
    short: true,
  },
  scaleX: {
    label: {
      text: 'Date',
    },
    values: statisticsDates,
    step: 'day',
    transform: {
      type: 'date',
      all: '%M %d',
    },
  },
  series: [
    {
      values: nationalActiveCases,
      backgroundColor: '#A4B9EA',
      border: '1px solid #fff',
      text: 'Active',
    },
    {
      values: nationalDeaths,
      backgroundColor: '#162B5E',
      border: '1px solid #fff',
      text: 'Deaths',
    },
    {
      values: nationalRecoveries,
      backgroundColor: '#4973D5',
      border: '1px solid #fff',
      text: 'Recoveries',
    },
  ],
})

export const getNationalDeathGraphConfig = (
  provinceData: ProvinceChartConfig,
  deathsPerProvince: number[][],
  statisticsDates: string[],
): any => {
  const provinces = clone(provinceData)

  map((province) => {
    province.values = prop(prop('text', province), deathsPerProvince)
    province.type = 'line'
  }, provinces)

  return {
    scaleY: {
      label: {
        text: 'Number of deaths',
      },
      short: true,
    },
    scaleX: {
      label: {
        text: 'Date',
      },

      values: statisticsDates,
      step: 'day',
      transform: {
        type: 'date',
        all: '%M %d',
      },
    },
    series: [
      {
        text: 'National Deaths',
        values: prop('ZA', deathsPerProvince),
        type: 'line',
        lineColor: '#a4a4a4',
      },
      ...provinces,
    ],
  }
}
export const getNationalTestingAverageGraphConfig = (
  testingDates: string[],
  publicTestingStats: number[],
  privateTestingStats: number[],
  dailyTestMovingAverage: number[],
) => ({
  scaleY: {
    label: {
      text: 'Number of cases',
    },
    short: true,
  },

  scaleX: {
    label: {
      text: 'Date',
    },
    values: testingDates,
    step: 'day',
    transform: {
      type: 'date',
      all: '%M %d',
    },
  },
  series: [
    {
      type: 'bar',
      text: 'Public',
      values: publicTestingStats,
      backgroundColor: '#A4B9EA',
      border: '1px solid #fff',
      stack: 1,
    },
    {
      type: 'bar',
      text: 'Private',
      values: privateTestingStats,
      backgroundColor: '#4973D5',
      border: '1px solid #fff',
      stack: 1,
    },
    {
      type: 'line',
      text: 'Average',
      values: dailyTestMovingAverage,
      lineColor: '#21418D',
      stack: 2,
    },
  ],
})

export const getNationalTestingPositivesGraphConfig = (
  positiveTestsDates: string[],
  positiveTests: number[],
) => ({
  scaleY: {
    label: {
      text: 'Percentage of cases',
    },
    short: true,
  },
  scaleX: {
    label: {
      text: 'Date',
    },
    values: positiveTestsDates,
    step: 'day',
    transform: {
      type: 'date',
      all: '%M %d',
    },
  },

  crosshairX: {
    plotLabel: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      padding: '8px 16px',
      borderColor: '#f5f5f5',
      fontColor: '#4a4a4a',
      text: '%v%',
    },
  },
  series: [
    {
      text: 'Positive test percentage of cases',
      values: positiveTests,
      lineColor: '#162b5e',
    },
  ],
})

export const getHospitalisationsChartConfig = (
  hospitalisationsDates: string[],
  currentlyAdmitted: number[],
  icuCases: number[],
  onVentilation: number[],
): any => ({
  scaleY: {
    label: {
      text: 'Number of cases',
    },
    short: true,
  },
  scaleX: {
    label: {
      text: 'Date',
    },
    values: hospitalisationsDates,
    step: 'day',
    transform: {
      type: 'date',
      all: '%M %d',
    },
  },
  series: [
    {
      text: 'Admitted',
      values: currentlyAdmitted,
      lineColor: '#162b5e',
    },
    {
      text: 'Admitted to ICU',
      values: icuCases,
      lineColor: '#dd1f26',
    },
    {
      text: 'On Ventilation',
      values: onVentilation,
      lineColor: '#a4b9ea',
    },
  ],
})

export const getTickerData = (
  latestStatistics: LatestStatistics,
  dailyChanges: DailyChangesData,
): any[] => [
  {
    title: 'Total Cases',
    amount: path(['ZA', 'confirmed'], latestStatistics),
    movement: createPercentageString(prop('confirmedPercentage', dailyChanges)),
    movementDirection: path(['confirmedPercentage'], dailyChanges) > 0,
    movementPositive: path(['confirmedPercentage'], dailyChanges) < 0,
  },
  {
    title: 'Total Deaths',
    amount: path(['ZA', 'deaths'], latestStatistics),
    movement: createPercentageString(prop('deathsPercentage', dailyChanges)),
    movementDirection: prop('deathsPercentage', dailyChanges) > 0,
    movementPositive: prop('deathsPercentage', dailyChanges) < 0,
  },
  {
    title: 'Active Cases',
    amount: path(['ZA', 'active'], latestStatistics),
    movement: createPercentageString(prop('activePercentage', dailyChanges)),
    movementDirection: prop('activePercentage', dailyChanges) > 0,
    movementPositive: prop('activePercentage', dailyChanges) < 0,
  },
  {
    title: 'Recoveries',
    amount: path(['ZA', 'recoveries'], latestStatistics),
    movement: createPercentageString(
      prop('recoveriesPercentage', dailyChanges),
    ),
    movementDirection: prop('recoveriesPercentage', dailyChanges) > 0,
    movementPositive: prop('recoveriesPercentage', dailyChanges) > 0,
  },
  {
    title: 'New Cases',
    amount: prop('newCases', dailyChanges),
    movement: '',
    movementDirection: false,
    movementPositive: false,
  },
  {
    title: 'New Deaths',
    amount: prop('newDeaths', dailyChanges),
    movement: '',
    movementDirection: false,
    movementPositive: false,
  },
  {
    title: 'Hospitalisations',
    amount: prop('hospitalisations', dailyChanges),
    movement: createPercentageString(
      prop('hospitalisationsPercentage', dailyChanges),
    ),
    movementDirection: prop('hospitalisationsPercentage', dailyChanges) > 0,
    movementPositive: prop('hospitalisationsPercentage', dailyChanges) < 0,
  },
]

export const getProvincialComparisonChartConfig = (
  chart: ProvincialComparisonChartTypes,
) => (
  latestStatistics: LatestStatistics,
  provinceChartConfig: ProvinceChartConfig,
): any => {
  const labels = values(PROVINCE_CODES)
  const barStyles = map(prop('lineColor'))(provinceChartConfig)
  const latestStatisticsProvinceProp =
    ProvincialComparisonChartStatisticsProp[chart]
  const seriesValues = map(
    pipe(propOr([], __, latestStatistics), prop(latestStatisticsProvinceProp)),
    values(PROVINCE_CODES),
  )

  return {
    scaleY: {
      label: {
        text: `Number of ${chart}`,
      },
      short: true,
    },
    scaleX: {
      label: {
        text: 'Province',
      },
      labels,
    },
    tooltip: {
      text: `%kl Total ${chart}: %v`,
    },
    plot: {
      styles: barStyles,
    },
    series: [{ values: seriesValues }],
  }
}

export const getProvincialComparisonCasesChartConfig = getProvincialComparisonChartConfig(
  ProvincialComparisonChartTypes.CASES,
)

export const getProvincialComparisonDeathsChartConfig = getProvincialComparisonChartConfig(
  ProvincialComparisonChartTypes.DEATHS,
)
