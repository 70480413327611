import {
  AdNames,
  AdPlatforms,
  AdSections,
  AdSites,
  AdSpotTestSlugs,
} from './Ads.types'
import { ADS_DESKTOP_CLASS_NAME, ADS_MOBILE_CLASS_NAME } from './Ads.constants'
import { WindowSize } from '../../common/utils/hooks.utils'
import { MOBILE_MAX_BREAKPOINT } from '../../common/styles/styles.constants'
import {
  conditionAndIsDev,
  getBooleanEnvVar,
  getEnvVar,
} from '../../common/modules/env/env.utils'
import { EnvVars } from '../../common/modules/env/env.types'
import { isNil } from 'ramda'

export const getIsMobile = (windowSizes: WindowSize): boolean | undefined => {
  if (isNil(windowSizes.width)) return undefined
  return windowSizes.width! < MOBILE_MAX_BREAKPOINT
}

export const getIsPlatform = (expectedPlatform: AdPlatforms) => (
  platform: AdPlatforms,
): boolean => platform === expectedPlatform

export const getIsMobileWebAdSlot = getIsPlatform(AdPlatforms.MOB_WEB)
export const getIsWebAdSlot = getIsPlatform(AdPlatforms.WEB)

export const getAdSlotStyleClass = (platform: AdPlatforms): string =>
  getIsMobileWebAdSlot(platform)
    ? ADS_MOBILE_CLASS_NAME
    : ADS_DESKTOP_CLASS_NAME

export const buildAdPathBase = (
  platform: AdPlatforms,
  site: AdSites,
  env: any = process.env,
): string => {
  const base = env.REACT_APP_AD_SLOT_BASE
  const covid19_slug = env.REACT_APP_AD_SLOT_COVID19DASH

  return [base, platform, site, covid19_slug].join('/').concat('/')
}

export const buildAdSlug = (section: AdSections, name: AdNames): string => {
  return [section, name].join('_')
}

export const getAdSlugOrTestSlug = (
  section: AdSections,
  name: AdNames,
): string =>
  conditionAndIsDev(getBooleanEnvVar(EnvVars.AD_SLOTS_OVERRIDE_FOR_DEV))
    ? AdSpotTestSlugs[section.toUpperCase()]
    : buildAdSlug(section, name)

export const buildAdPath = (
  section: AdSections,
  name: AdNames,
  platform: AdPlatforms,
  env: any = process.env,
): string => {
  const adSlug = getAdSlugOrTestSlug(section, name)

  // TODO: this should probably be moved to a generic spot so we can use elsewhere
  const site =
    getEnvVar(EnvVars.LANGUAGE, env) === 'af'
      ? AdSites.NETWERK24
      : AdSites.NEWS24
  const adBasePath = buildAdPathBase(platform, site)

  return adBasePath.concat(adSlug)
}
