import React, { FunctionComponent } from 'react'
import { drop, map, path, prop } from 'ramda'
import DOMPurify from 'dompurify'
import { Link, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { WithTranslation } from 'react-i18next'

import MainContent from '../MainContent'
import AdsProvider, { AdSlot, AdNames, AdPlatforms, AdSections } from '../Ads'
import {
  DailyChangesData,
  LatestStatistics,
  MainContentSection,
  PageContentSection,
} from './types'
import StackedBarChart from '../../common/components/Charts/StackedBarChart'
import StackedBarAndLine from '../../common/components/Charts/StackedBarAndLine'
import Line from '../../common/components/Charts/Line'
import {
  brandColors,
  provinceData,
} from '../../common/components/Charts/chartOptions'
import ContentSection from '../ContentSection'
import { Map } from '../Maps/components/Map'
import Tickers from '../Tickers'
import {
  calculateMapItemAlphaValue,
  getPrettyDate,
  getProvincePath,
  getProvinceLinkProperties,
} from '../../common/utils/data'
import { formatNumberWithSpaces } from '../../common/utils/functions'
import Optional from '../../common/components/Optional'
import LoadUntilReady from '../../common/components/LoadUntilReady'
import BarChart from '../../common/components/Charts/BarChart'
import {
  getDailyCasesGraphConfig,
  getHospitalisationsChartConfig,
  getNationalCasesGraphConfig,
  getNationalDeathGraphConfig,
  getNationalTestingAverageGraphConfig,
  getNationalTestingPositivesGraphConfig,
  getProvincialComparisonCasesChartConfig,
  getProvincialComparisonDeathsChartConfig,
  getTickerData,
} from './Country.utils'
import { PROVINCE_NAMES, PROVINCE_PATHS } from '../Province/Province.types'
import FeatureArticle from '../FeatureArticle'
import RelatedArticles from '../RelatedArticles'
import MostReadArticles from '../MostReadArticles'

interface Props {
  confirmedCasesByProvince: number[]
  confirmedCasesMovingAverage: string[]
  currentlyAdmitted: number[]
  dailyCasesContent: PageContentSection
  dailyCasesDates: string[]
  dailyChanges: DailyChangesData
  dailyTestMovingAverage: number[]
  deathsContent: PageContentSection
  deathsPerProvince: number[][]
  hospitalisationsContent: PageContentSection
  hospitalisationsDates: string[]
  provincialComparisonsContent: PageContentSection
  icuCases: number[]
  latestStatistics: LatestStatistics
  mainContent: MainContentSection
  nationalActiveCases: number[]
  nationalCasesContent: PageContentSection
  nationalDeaths: number[]
  nationalRecoveries: number[]
  nationalTestingContent: PageContentSection
  onVentilation: number[]
  positiveTests: number[]
  positiveTestsDates: string[]
  privateTestingStats: number[]
  publicTestingStats: number[]
  statisticsDates: string[]
  testingDates: string[]
}

const Country: FunctionComponent<Props & WithTranslation> = ({
  t,
  i18n,
  mainContent,
  dailyCasesContent,
  nationalCasesContent,
  deathsContent,
  nationalTestingContent,
  hospitalisationsContent,
  provincialComparisonsContent,
  latestStatistics,
  dailyChanges,
  confirmedCasesByProvince,
  confirmedCasesMovingAverage,
  nationalDeaths,
  nationalRecoveries,
  nationalActiveCases,
  deathsPerProvince,
  statisticsDates,
  currentlyAdmitted,
  icuCases,
  onVentilation,
  testingDates,
  hospitalisationsDates,
  publicTestingStats,
  privateTestingStats,
  dailyTestMovingAverage,
  positiveTests,
  positiveTestsDates,
  dailyCasesDates,
}) => {
  const history = useHistory()

  const mapOptions = {
    zooming: false,
    panning: false,
    scrolling: false,
    tooltip: {
      'callout': true,
      'text': `<div class="tooltip"><div class="title"><h6>%data-title</h6><span>%data-date</span></div><div class="stat">Total Cases <div class="amount">%data-total-cases</div></div><div class="stat">Active cases <div class="amount">%data-active-cases</div></div><div class="stat">Recovered<div class="amount">%data-recovered</div></div><div class="stat">Deaths<div class="amount">%data-deaths</div></div></div>`,
      'html-mode': true,
      'backgroundColor': '#fff',
      'borderRadius': '4px',
      'padding': '0',
      'fontColor': '#212121',
      'borderColor': '#f5f5f5',
      'shadow': 'false',
      'font-family': '"Proxima Nova", sans-serif',
    },
    items: {
      WC: {
        'data-title': t('Western Cape'),
        'data-date': getPrettyDate(path(['WC', 'date'], latestStatistics)),
        'data-total-cases': formatNumberWithSpaces(
          path(['WC', 'confirmed'], latestStatistics),
        ),
        'data-active-cases': formatNumberWithSpaces(
          path(['WC', 'active'], latestStatistics),
        ),
        'data-recovered': formatNumberWithSpaces(
          path(['WC', 'recoveries'], latestStatistics),
        ),
        'data-deaths': formatNumberWithSpaces(
          path(['WC', 'deaths'], latestStatistics),
        ),
        'backgroundColor': '#dd1f26',
        'alpha': calculateMapItemAlphaValue(
          path(['WC', 'confirmed'], latestStatistics) /
            path(['ZA', 'confirmed'], latestStatistics),
        ),
        'hoverState': {
          alpha: 1,
          backgroundColor: brandColors[i18n.language],
        },
      },
      EC: {
        'data-title': t('Eastern Cape'),
        'data-date': getPrettyDate(path(['EC', 'date'], latestStatistics)),
        'data-total-cases': formatNumberWithSpaces(
          path(['EC', 'confirmed'], latestStatistics),
        ),
        'data-active-cases': formatNumberWithSpaces(
          path(['EC', 'active'], latestStatistics),
        ),
        'data-recovered': formatNumberWithSpaces(
          path(['EC', 'recoveries'], latestStatistics),
        ),
        'data-deaths': formatNumberWithSpaces(
          path(['EC', 'deaths'], latestStatistics),
        ),
        'backgroundColor': '#dd1f26',
        'alpha': calculateMapItemAlphaValue(
          path(['EC', 'confirmed'], latestStatistics) /
            path(['ZA', 'confirmed'], latestStatistics),
        ),
        'hoverState': {
          alpha: 1,
          backgroundColor: brandColors[i18n.language],
        },
      },
      NC: {
        'data-title': t('Northern Cape'),
        'data-date': getPrettyDate(path(['NC', 'date'], latestStatistics)),
        'data-total-cases': formatNumberWithSpaces(
          path(['NC', 'confirmed'], latestStatistics),
        ),
        'data-active-cases': formatNumberWithSpaces(
          path(['NC', 'active'], latestStatistics),
        ),
        'data-recovered': formatNumberWithSpaces(
          path(['NC', 'recoveries'], latestStatistics),
        ),
        'data-deaths': formatNumberWithSpaces(
          path(['NC', 'deaths'], latestStatistics),
        ),
        'backgroundColor': '#dd1f26',
        'alpha': calculateMapItemAlphaValue(
          path(['NC', 'confirmed'], latestStatistics) /
            path(['ZA', 'confirmed'], latestStatistics),
        ),
        'hoverState': {
          alpha: 1,
          backgroundColor: brandColors[i18n.language],
        },
      },
      GP: {
        'data-title': t('Gauteng'),
        'data-date': getPrettyDate(path(['GP', 'date'], latestStatistics)),
        'data-total-cases': formatNumberWithSpaces(
          path(['GP', 'confirmed'], latestStatistics),
        ),
        'data-active-cases': formatNumberWithSpaces(
          path(['GP', 'active'], latestStatistics),
        ),
        'data-recovered': formatNumberWithSpaces(
          path(['GP', 'recoveries'], latestStatistics),
        ),
        'data-deaths': formatNumberWithSpaces(
          path(['GP', 'deaths'], latestStatistics),
        ),
        'backgroundColor': '#dd1f26',
        'alpha': calculateMapItemAlphaValue(
          path(['GP', 'confirmed'], latestStatistics) /
            path(['ZA', 'confirmed'], latestStatistics),
        ),
        'hoverState': {
          alpha: 1,
          backgroundColor: brandColors[i18n.language],
        },
      },
      LP: {
        'data-title': t('Limpopo'),
        'data-date': getPrettyDate(path(['LP', 'date'], latestStatistics)),
        'data-total-cases': formatNumberWithSpaces(
          path(['LP', 'confirmed'], latestStatistics),
        ),
        'data-active-cases': formatNumberWithSpaces(
          path(['LP', 'active'], latestStatistics),
        ),
        'data-recovered': formatNumberWithSpaces(
          path(['LP', 'recoveries'], latestStatistics),
        ),
        'data-deaths': formatNumberWithSpaces(
          path(['LP', 'deaths'], latestStatistics),
        ),
        'backgroundColor': '#dd1f26',
        'alpha': calculateMapItemAlphaValue(
          path(['LP', 'confirmed'], latestStatistics) /
            path(['ZA', 'confirmed'], latestStatistics),
        ),
        'hoverState': {
          alpha: 1,
          backgroundColor: brandColors[i18n.language],
        },
      },
      NW: {
        'data-title': t('North West'),
        'data-date': getPrettyDate(path(['NW', 'date'], latestStatistics)),
        'data-total-cases': formatNumberWithSpaces(
          path(['NW', 'confirmed'], latestStatistics),
        ),
        'data-active-cases': formatNumberWithSpaces(
          path(['NW', 'active'], latestStatistics),
        ),
        'data-recovered': formatNumberWithSpaces(
          path(['NW', 'recoveries'], latestStatistics),
        ),
        'data-deaths': formatNumberWithSpaces(
          path(['NW', 'deaths'], latestStatistics),
        ),
        'backgroundColor': '#dd1f26',
        'alpha': calculateMapItemAlphaValue(
          path(['NW', 'confirmed'], latestStatistics) /
            path(['ZA', 'confirmed'], latestStatistics),
        ),
        'hoverState': {
          alpha: 1,
          backgroundColor: brandColors[i18n.language],
        },
      },
      FS: {
        'data-title': t('Free State'),
        'data-date': getPrettyDate(path(['FS', 'date'], latestStatistics)),
        'data-total-cases': formatNumberWithSpaces(
          path(['FS', 'confirmed'], latestStatistics),
        ),
        'data-active-cases': formatNumberWithSpaces(
          path(['FS', 'active'], latestStatistics),
        ),
        'data-recovered': formatNumberWithSpaces(
          path(['FS', 'recoveries'], latestStatistics),
        ),
        'data-deaths': formatNumberWithSpaces(
          path(['FS', 'deaths'], latestStatistics),
        ),
        'backgroundColor': '#dd1f26',
        'alpha': calculateMapItemAlphaValue(
          path(['FS', 'confirmed'], latestStatistics) /
            path(['ZA', 'confirmed'], latestStatistics),
        ),
        'hoverState': {
          alpha: 1,
          backgroundColor: brandColors[i18n.language],
        },
      },
      KZN: {
        'data-title': t('KwaZulu-Natal'),
        'data-date': getPrettyDate(path(['KZN', 'date'], latestStatistics)),
        'data-total-cases': formatNumberWithSpaces(
          path(['KZN', 'confirmed'], latestStatistics),
        ),
        'data-active-cases': formatNumberWithSpaces(
          path(['KZN', 'active'], latestStatistics),
        ),
        'data-recovered': formatNumberWithSpaces(
          path(['KZN', 'recoveries'], latestStatistics),
        ),
        'data-deaths': formatNumberWithSpaces(
          path(['KZN', 'deaths'], latestStatistics),
        ),
        'backgroundColor': '#dd1f26',
        'alpha': calculateMapItemAlphaValue(
          path(['KZN', 'confirmed'], latestStatistics) /
            path(['ZA', 'confirmed'], latestStatistics),
        ),
        'hoverState': {
          alpha: 1,
          backgroundColor: brandColors[i18n.language],
        },
      },
      MP: {
        'data-title': t('Mpumalanga'),
        'data-date': getPrettyDate(path(['MP', 'date'], latestStatistics)),
        'data-total-cases': formatNumberWithSpaces(
          path(['MP', 'confirmed'], latestStatistics),
        ),
        'data-active-cases': formatNumberWithSpaces(
          path(['MP', 'active'], latestStatistics),
        ),
        'data-recovered': formatNumberWithSpaces(
          path(['MP', 'recoveries'], latestStatistics),
        ),
        'data-deaths': formatNumberWithSpaces(
          path(['MP', 'deaths'], latestStatistics),
        ),
        'backgroundColor': '#dd1f26',
        'alpha': calculateMapItemAlphaValue(
          path(['MP', 'confirmed'], latestStatistics) /
            path(['ZA', 'confirmed'], latestStatistics),
        ),
        'hoverState': {
          alpha: 1,
          backgroundColor: brandColors[i18n.language],
        },
      },
    },
  }

  const tickerData = getTickerData(latestStatistics, dailyChanges)

  const dailyCasesGraphConfig = getDailyCasesGraphConfig(
    dailyCasesDates,
    confirmedCasesByProvince,
    confirmedCasesMovingAverage,
  )

  const nationalCasesGraphConfig = getNationalCasesGraphConfig(
    statisticsDates,
    nationalActiveCases,
    nationalDeaths,
    nationalRecoveries,
  )

  const nationalDeathGraphConfig = getNationalDeathGraphConfig(
    provinceData,
    deathsPerProvince,
    statisticsDates,
  )

  const nationalTestingAverageGraphConfig = getNationalTestingAverageGraphConfig(
    drop(1)(testingDates),
    publicTestingStats,
    privateTestingStats,
    drop(1)(dailyTestMovingAverage),
  )
  const nationalTestingPositivesGraphConfig = getNationalTestingPositivesGraphConfig(
    positiveTestsDates,
    positiveTests,
  )

  const hospitalisationsChartConfig = getHospitalisationsChartConfig(
    hospitalisationsDates,
    currentlyAdmitted,
    icuCases,
    onVentilation,
  )

  const provincialComparisonCasesChartConfig = getProvincialComparisonCasesChartConfig(
    latestStatistics,
    provinceData,
  )

  const provincialComparisonDeathsChartConfig = getProvincialComparisonDeathsChartConfig(
    latestStatistics,
    provinceData,
  )

  const provinceLinks = getProvinceLinkProperties(
    PROVINCE_PATHS,
    PROVINCE_NAMES,
  )

  const renderProvinceLink = ({ path, title }) => {
    return path && title ? (
      <Link key={path} className="button" to={path}>
        {t(title)}
      </Link>
    ) : null
  }

  return (
    <div className="container">
      <Helmet>
        <title>{`${t('siteTitle')} | ${t('South Africa')}`}</title>
      </Helmet>
      <AdsProvider>
        <AdSlot section={AdSections.NATIONAL} name={AdNames.LB1} />
        <AdSlot
          section={AdSections.NATIONAL}
          name={AdNames.STICKY}
          platform={AdPlatforms.MOB_WEB}
        />
        <Optional condition={!!mainContent}>
          <MainContent
            title={t('Covid-19 in South Africa')}
            date={prop('last_updated_on', mainContent)}
            content={prop('content', mainContent)}
          >
            <LoadUntilReady isLoading={!latestStatistics}>
              <>
                <Map
                  mapName="ZA"
                  mapOptions={mapOptions}
                  shapeClick={(shape) => {
                    if (
                      shape &&
                      getProvincePath(shape.shapeid, PROVINCE_PATHS)
                    ) {
                      history.push(
                        getProvincePath(shape.shapeid, PROVINCE_PATHS),
                      )
                    }
                  }}
                />
                <Tickers tickerData={tickerData} />
              </>
            </LoadUntilReady>
          </MainContent>
        </Optional>
        <Optional condition={!!dailyCasesContent}>
          <ContentSection content={dailyCasesContent}>
            <LoadUntilReady isLoading={!latestStatistics}>
              <StackedBarAndLine chartData={dailyCasesGraphConfig} />
            </LoadUntilReady>
          </ContentSection>
        </Optional>
        <AdSlot section={AdSections.NATIONAL} name={AdNames.LB2} />
        <AdSlot
          section={AdSections.NATIONAL}
          name={AdNames.MREC1}
          platform={AdPlatforms.MOB_WEB}
        />
        <FeatureArticle />
        <Optional condition={!!nationalCasesContent}>
          <ContentSection
            sectionName="national-cases"
            content={nationalCasesContent}
          >
            <LoadUntilReady isLoading={!latestStatistics}>
              <StackedBarChart chartData={nationalCasesGraphConfig} />
            </LoadUntilReady>
          </ContentSection>
        </Optional>
        <AdSlot section={AdSections.NATIONAL} name={AdNames.LB3} />
        <AdSlot
          section={AdSections.NATIONAL}
          name={AdNames.MREC2}
          platform={AdPlatforms.MOB_WEB}
        />
        <Optional condition={!!deathsContent}>
          <ContentSection sectionName="deaths" content={deathsContent}>
            <LoadUntilReady isLoading={!latestStatistics}>
              <Line chartData={nationalDeathGraphConfig} />
            </LoadUntilReady>
          </ContentSection>
        </Optional>
        <MostReadArticles />
        <Optional condition={!!nationalTestingContent}>
          <ContentSection
            sectionName="testing"
            content={nationalTestingContent}
          >
            <h5>7-Day Average: National Daily Tests: Public & Private</h5>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  path(['7_day_average', 'content'], nationalTestingContent),
                ),
              }}
            />
            <LoadUntilReady isLoading={!latestStatistics}>
              <StackedBarAndLine
                chartData={nationalTestingAverageGraphConfig}
              />
            </LoadUntilReady>
            <h5>National Daily Tests - percent positive</h5>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  path(
                    ['national_daily_positive_tests', 'content'],
                    nationalTestingContent,
                  ),
                ),
              }}
            />
            <LoadUntilReady isLoading={!latestStatistics}>
              <Line chartData={nationalTestingPositivesGraphConfig} />
            </LoadUntilReady>
          </ContentSection>
        </Optional>
        <AdSlot
          section={AdSections.NATIONAL}
          name={AdNames.MREC3}
          platform={AdPlatforms.MOB_WEB}
        />
        <Optional condition={!!hospitalisationsContent}>
          <ContentSection
            sectionName="hospitalisations"
            content={hospitalisationsContent}
          >
            <LoadUntilReady isLoading={!latestStatistics}>
              <Line chartData={hospitalisationsChartConfig} />
            </LoadUntilReady>
          </ContentSection>
        </Optional>
        <Optional condition={!!provincialComparisonsContent}>
          <ContentSection
            sectionName="provincial-comparisons"
            content={provincialComparisonsContent}
          >
            <div className="col-layout">
              <div className="col">
                <div className="col-content">
                  <h5>Cases</h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        path(
                          ['cases', 'content'],
                          provincialComparisonsContent,
                        ),
                      ),
                    }}
                  />
                </div>
                <LoadUntilReady isLoading={!latestStatistics}>
                  <BarChart chartData={provincialComparisonCasesChartConfig} />
                </LoadUntilReady>
              </div>
              <div className="col">
                <div className="col-content">
                  <h5>Deaths</h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        path(
                          ['deaths', 'content'],
                          provincialComparisonsContent,
                        ),
                      ),
                    }}
                  />
                </div>
                <LoadUntilReady isLoading={!latestStatistics}>
                  <BarChart chartData={provincialComparisonDeathsChartConfig} />
                </LoadUntilReady>
              </div>
            </div>
          </ContentSection>
        </Optional>
        <RelatedArticles />
        <section style={{ marginBottom: '1.5rem' }}>
          <hr />
          <h3>{t('View a Province')}</h3>
          {map(renderProvinceLink, provinceLinks)}
        </section>
      </AdsProvider>
    </div>
  )
}

export default Country
