import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './common/i18n'
import 'normalize.css'
import TagManager from 'react-gtm-module'

import store from './common/redux/store'
import { App } from './modules/App'
import * as serviceWorker from './serviceWorker'

const gtmId =
  process.env.REACT_APP_LANGUAGE === 'en'
    ? 'GTM-5FF4NNT'
    : process.env.REACT_APP_LANGUAGE === 'af'
    ? 'GTM-P66VF4X'
    : false

gtmId &&
  TagManager.initialize({
    gtmId: gtmId,
  })

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
