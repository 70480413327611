import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

const NotFound: FunctionComponent = () => {
  return (
    <div>
      <h1>404 Page not found</h1>
      <p>
        <Link to="/">Click here</Link> to return to the homepage
      </p>
    </div>
  )
}

export default NotFound
