import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

//---------------------------------
// actions
//---------------------------------

export const API_LOAD_PAGE_DATA = '[provincePage] fetch page content'
export const loadPageData = (payload): AnyAction => ({
  type: API_LOAD_PAGE_DATA,
  payload,
})

export const API_LOAD_PAGE_DATA_SUCCESS =
  '[provincePage] fetch page content success'
export const loadPageDataSuccess = (payload): AnyAction => ({
  type: API_LOAD_PAGE_DATA_SUCCESS,
  payload,
})
export const SET_PAGE_CONTENT = '[provincePage] set page content'
export const setPageContent = (payload): AnyAction => ({
  type: SET_PAGE_CONTENT,
  payload,
})

export const SET_STATISTICS_DATA = '[provincePage] set statistics data'
export const setStatisticsData = (payload): AnyAction => ({
  type: SET_STATISTICS_DATA,
  payload,
})

export const API_LOAD_PAGE_DATA_FAILURE =
  '[provincePage] set page content failure'
export const loadPageDataFailure = (): AnyAction => ({
  type: API_LOAD_PAGE_DATA_FAILURE,
})

//---------------------------------
// reducers
//---------------------------------

const overwriteContent = (state, { payload }): any =>
  assoc('content', payload, state)

const overwriteStatistics = (state, { payload }): any =>
  assoc('statistics', payload, state)

//---------------------------------
// reducer
//---------------------------------

export const INITIAL_STATE = {
  content: {
    highlights: {},
  },
  statistics: {
    caseHistory: {
      deaths: {
        EC: {},
        FS: {},
        GP: {},
        KZN: {},
        LP: {},
        MP: {},
        NC: {},
        NW: {},
        WC: {},
        ZA: {},
      },
      recoveries: {
        EC: {},
        FS: {},
        GP: {},
        KZN: {},
        LP: {},
        MP: {},
        NC: {},
        NW: {},
        WC: {},
        ZA: {},
      },
      active: {
        EC: {},
        FS: {},
        GP: {},
        KZN: {},
        LP: {},
        MP: {},
        NC: {},
        NW: {},
        WC: {},
        ZA: {},
      },
    },
    movingAverage: {
      confirmed: [],
    },
    hospitalisations: {
      currentlyAdmitted: {},
      icu: {},
      ventilated: {},
    },
  },
}

export const reducer = createReducer(INITIAL_STATE as any, {
  [SET_PAGE_CONTENT]: overwriteContent,
  [SET_STATISTICS_DATA]: overwriteStatistics,
})
