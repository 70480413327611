import React from 'react'

import { IconProps } from './types'

const Twitter = (props: IconProps): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    width={props.width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || '#000'}
      d="M21 6a9.27 9.27 0 002.72-.73 10.17 10.17 0 01-2.37 2.43C22.63 17.11 9.85 26.05.68 19.63a9.57 9.57 0 007-2 4.7 4.7 0 01-4.41-3.23 5.28 5.28 0 002.13-.09 4.73 4.73 0 01-3.79-4.69 4.79 4.79 0 002.14.6A4.78 4.78 0 012.29 3.9 13.36 13.36 0 0012 8.84C10.81 4.27 17 1 20.08 4.53a9.18 9.18 0 003-1.14A4.66 4.66 0 0121 6z"
    />
  </svg>
)

export default Twitter
