export enum SUB_DISTRICT_NAMES {
  Eastern = 'Eastern',
  Klipfontein = 'Klipfontein',
  Khayelitsha = 'Khayelitsha',
  MitchellsPlain = 'Mitchells Plain',
  Northern = 'Northern',
  Southern = 'Southern',
  Tygerberg = 'Tygerberg',
  Western = 'Western',
  BreedeValley = 'Breede Valley',
  Drakenstein = 'Drakenstein',
  Langeberg = 'Langeberg',
  Stellenbosch = 'Stellenbosch',
  Witzenberg = 'Witzenberg',
  BeaufortWest = 'Beaufort West',
  Laingsburg = 'Laingsburg',
  PrinceAlbert = 'Prince Albert',
  Bitou = 'Bitou',
  George = 'George',
  Hessequa = 'Hessequa',
  Kannaland = 'Kannaland',
  Knysna = 'Knysna',
  MosselBay = 'Mossel Bay',
  Oudtshoorn = 'Oudtshoorn',
  CapeAgulhas = 'Cape Agulhas',
  Overstrand = 'Overstrand',
  Swellendam = 'Swellendam',
  Theewaterskloof = 'Theewaterskloof',
  Bergriver = 'Bergriver',
  Cederberg = 'Cederberg',
  Matzikama = 'Matzikama',
  SaldanhaBay = 'Saldanha Bay',
  Swartland = 'Swartland',
  East1 = 'East 1',
  East2 = 'East 2',
  North1 = 'North 1',
  North2 = 'North 2',
  South1 = 'South 1',
  South2 = 'South 2',
  CojA = 'COJ A',
  CojB = 'COJ B',
  CojC = 'COJ C',
  CojD = 'COJ D',
  CojE = 'COJ E',
  CojF = 'COJ F',
  CojG = 'COJ G',
  Emfuleni = 'Emfuleni',
  Lesedi = 'Lesedi',
  Midvaal = 'Midvaal',
  Cot1 = 'COT 1',
  Cot2 = 'COT 2',
  Cot3 = 'COT 3',
  Cot4 = 'COT 4',
  Cot5 = 'COT 5',
  Cot6 = 'COT 6',
  Cot7 = 'COT 7',
  MerafongCity = 'Merafong City',
  MogaleCity = 'Mogale City',
  RandWestCity = 'Rand West City',
}

export enum SUB_DISTRICT_PATHS {
  'Eastern' = '/eastern',
  'Klipfontein' = '/klipfontein',
  'Khayelitsha' = '/khayelitsha',
  'Mitchells Plain' = '/mitchells-plain',
  'Northern' = '/northern',
  'Southern' = '/southern',
  'Tygerberg' = '/tygerberg',
  'Western' = '/western',
  'Breede Valley' = '/breede-valley',
  'Drakenstein' = '/drakenstein',
  'Langeberg' = '/langeberg',
  'Stellenbosch' = '/stellenbosch',
  'Witzenberg' = '/witzenberg',
  'Beaufort West' = '/beaufort-west',
  'Laingsburg' = '/laingsburg',
  'Prince Albert' = '/prince-albert',
  'Bitou' = '/bitou',
  'George' = '/george',
  'Hessequa' = '/hessequa',
  'Kannaland' = '/kannaland',
  'Knysna' = '/knysna',
  'Mossel Bay' = '/mossel-bay',
  'Oudtshoorn' = '/oudtshoorn',
  'Cape Agulhas' = '/cape-agulhas',
  'Overstrand' = '/overstrand',
  'Swellendam' = '/swellendam',
  'Theewaterskloof' = '/theewaterskloof',
  'Bergriver' = '/bergriver',
  'Cederberg' = '/cederberg',
  'Matzikama' = '/matzikama',
  'Saldanha Bay' = '/saldanha-bay',
  'Swartland' = '/swartland',
  'East 1' = '/east-1',
  'East 2' = '/east-2',
  'North 1' = '/north-1',
  'North 2' = '/north-2',
  'South 1' = '/south-1',
  'South 2' = '/south-2',
  'COJ A' = '/coj-a',
  'COJ B' = '/coj-b',
  'COJ C' = '/coj-c',
  'COJ D' = '/coj-d',
  'COJ E' = '/coj-e',
  'COJ F' = '/coj-f',
  'COJ G' = '/coj-g',
  'Emfuleni' = '/emfuleni',
  'Lesedi' = '/lesedi',
  'Midvaal' = '/midvaal',
  'COT 1' = '/cot-1',
  'COT 2' = '/cot-2',
  'COT 3' = '/cot-3',
  'COT 4' = '/cot-4',
  'COT 5' = '/cot-5',
  'COT 6' = '/cot-6',
  'COT 7' = '/cot-7',
  'Merafong City' = '/merafong-city',
  'Mogale City' = '/mogale-city',
  'Rand West City' = '/rand-west-city',
}
