import { AnyAction } from '@reduxjs/toolkit'
import { filter, path, prop } from 'ramda'

import {
  loadPageData,
  setStatisticsData as setProvinceStatistics,
  INITIAL_STATE as PROVINCE_INITIAL_STATE,
} from '../Province/province.reducer'
import { dispatch as DispatchType } from '../../common/redux/types'
import {
  loadCountryPageData,
  setStatisticsData as setCountryStatistics,
  INITIAL_STATE as COUNTRY_INITIAL_STATE,
} from '../Country/country.reducer'
import {
  loadDistrictPageData,
  setStatisticsData as setDistrictStatistics,
  INITIAL_STATE as DISTRICT_INITIAL_STATE,
} from '../District/district.reducer'
import {
  loadLatestArticlesData,
  loadMostReadArticlesData,
} from '../NewsArticles'

export const pageChangeFlow = ({ dispatch }: DispatchType) => (
  next: (action: AnyAction) => void,
) => (action: AnyAction): void => {
  next(action)

  const { type, payload } = action

  if (type === '@@router/LOCATION_CHANGE') {
    dispatch(setCountryStatistics(prop('statistics', COUNTRY_INITIAL_STATE)))
    dispatch(setProvinceStatistics(prop('statistics', PROVINCE_INITIAL_STATE)))
    dispatch(setDistrictStatistics(prop('statistics', DISTRICT_INITIAL_STATE)))
    dispatch(loadLatestArticlesData())
    dispatch(loadMostReadArticlesData())
    if (path(['location', 'pathname'], payload) === '/') {
      dispatch(
        loadCountryPageData({
          pageToLoad: 'south-africa',
          dataType: 'statistics',
        }),
      )
      dispatch(
        loadCountryPageData({
          pageToLoad: 'south-africa',
          dataType: 'content',
        }),
      )
    }

    const provincePath = path(['location', 'pathname'], payload)
    const provincePathSegments = filter(
      (pathSegment) => pathSegment !== '',
      provincePath.split('/'),
    )

    if (provincePathSegments.length === 1) {
      dispatch(
        loadPageData({
          pageToLoad: `south-africa${provincePath}`,
          dataType: 'statistics',
        }),
      )
      dispatch(
        loadPageData({
          pageToLoad: `south-africa${provincePath}`,
          dataType: 'content',
        }),
      )
    }

    if (provincePathSegments.length === 2) {
      dispatch(
        loadDistrictPageData({
          pageToLoad: `south-africa${provincePath}`,
          dataType: 'statistics',
        }),
      )
      dispatch(
        loadDistrictPageData({
          pageToLoad: `south-africa${provincePath}`,
          dataType: 'content',
        }),
      )
    }
  }
}
