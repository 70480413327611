import { path, head, tail, unless, isNil, identity } from 'ramda'
import { createSelector } from 'reselect'

import { RootState } from '../../common/redux/types'

export const getLatestArticles = (state: RootState) =>
  path(['newsArticles', 'latestArticles'], state)

export const getMostReadArticles = (state: RootState) =>
  path(['newsArticles', 'mostReadArticles'], state)

export const getFeaturedArticleSelector = createSelector(
  [getLatestArticles],
  unless(isNil, head),
)

export const getAllButFirstLatestArticlesSelector = createSelector(
  [getLatestArticles],
  unless(isNil, tail),
)

export const getMostReadArticlesSelector = createSelector(
  [getMostReadArticles],
  identity,
)
