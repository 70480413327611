export enum AdSites {
  NEWS24 = 'News24',
  NETWERK24 = 'Netwerk24',
}

export enum AdPlatforms {
  WEB = 'Web',
  MOB_WEB = 'Mobile-Web',
}

export enum AdNames {
  LB1 = 'LB1',
  LB2 = 'LB2',
  LB3 = 'LB3',
  MREC1 = 'MREC1',
  MREC2 = 'MREC2',
  MREC3 = 'MREC3',
  STICKY = 'STICKY',
}

export enum AdSections {
  ABOUT = 'About',
  DISTRICT = 'District',
  NATIONAL = 'National',
  PROVINCIAL = 'Provincial',
}
export enum AdSpotTestSlugs {
  ABOUT = 'Test1',
  DISTRICT = 'Test2',
  NATIONAL = 'Test3',
  PROVINCIAL = 'Test4',
}
export type AdSize = number[][]
export enum AdSizeOptions {
  SIZE_728_X_90 = 'SIZE_728_X_90',
  SIZE_300_X_250 = 'SIZE_300_X_250',
  SIZE_320_X_50 = 'SIZE_320_X_50',
}
export type AdSizes = Record<AdSizeOptions, AdSize>

export interface AdSlot {
  name: AdNames
  section: AdSections
  platform: AdPlatforms
}
