import { createSelector } from 'reselect'
import { compose, map, prop, path, fromPairs, values } from 'ramda'
import { getKeys, getValues } from '../../common/utils/data'
import { RootState } from '../../common/redux/types'

export const getCurrentPageDistrictCode = (districtPaths) => (
  state: RootState,
) => {
  const currentPath = path(['router', 'location', 'pathname'])(state)
  return prop(currentPath, districtPaths)
}

export const getCurrentPageDistrictCodeSelector = (districtPaths) =>
  createSelector(
    [getCurrentPageDistrictCode(districtPaths)],
    (content) => content,
  )

export const getMainContent = path(['district', 'content', 'highlights'])
export const getMainContentSelector = createSelector(
  [getMainContent],
  (content) => content,
)

export const getDailyCasesContent = path(['district', 'content', 'daily_cases'])
export const getDailyCasesContentSelector = createSelector(
  [getDailyCasesContent],
  (content) => content,
)

export const getDistrictCasesContent = path([
  'district',
  'content',
  'cases_overview',
])
export const getDistrictCasesContentSelector = createSelector(
  [getDistrictCasesContent],
  (content) => content,
)

export const getCasesBySubDistrictContent = path([
  'district',
  'content',
  'daily_cases_by_subdistrict',
])
export const getCasesBySubDistrictContentSelector = createSelector(
  [getCasesBySubDistrictContent],
  (content) => content,
)

export const getLatestStatistics = path(['district', 'statistics', 'latest'])
export const getLatestStatisticsSelector = createSelector(
  [getLatestStatistics],
  (content) => content,
)

export const getDailyChanges = path(['district', 'statistics', 'dailyChanges'])
export const getDailyChangesSelector = createSelector(
  [getDailyChanges],
  (content) => content,
)

export const getConfirmedCasesByDistrict = compose(
  map(getValues),
  path(['district', 'statistics', 'caseHistory', 'confirmed']),
)

export const getConfirmedCasesByDistrictSelector = createSelector(
  [getConfirmedCasesByDistrict],
  (content) => content,
)

export const getRecoveriesByDistrict = compose(
  map(getValues),
  path(['district', 'statistics', 'caseHistory', 'recoveries']),
)

export const getRecoveriesByDistrictSelector = createSelector(
  [getRecoveriesByDistrict],
  (content) => content,
)

export const getConfirmedCasesByDistrictDates = compose(
  map(getKeys),
  path(['district', 'statistics', 'caseHistory', 'confirmed']),
)

export const getConfirmedCasesByDistrictDatesSelector = createSelector(
  [getConfirmedCasesByDistrictDates],
  (content) => content,
)

export const getConfirmedCasesMovingAverage = compose(
  values,
  fromPairs,
  path(['district', 'statistics', 'movingAverage', 'confirmed']),
)

export const getConfirmedCasesMovingAverageSelector = createSelector(
  [getConfirmedCasesMovingAverage],
  (content) => content,
)
