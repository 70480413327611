export enum PROVINCE_CODES {
  WC = 'WC',
  GP = 'GP',
  NW = 'NW',
  NC = 'NC',
  FS = 'FS',
  EC = 'EC',
  MP = 'MP',
  LP = 'LP',
  KZN = 'KZN',
}

export enum PROVINCE_PATHS {
  GP = '/gauteng',
  WC = '/western-cape',
  KZN = '/kwazulu-natal',
  EC = '/eastern-cape',
  FS = '/free-state',
  LP = '/limpopo',
  MP = '/mpumalanga',
  NC = '/northern-cape',
  NW = '/north-west',
}

export enum PROVINCE_NAMES {
  GP = 'Gauteng',
  WC = 'Western Cape',
  KZN = 'KwaZulu-Natal',
  EC = 'Eastern Cape',
  FS = 'Free State',
  LP = 'Limpopo',
  MP = 'Mpumalanga',
  NC = 'Northern Cape',
  NW = 'North West',
}

export interface PROVINCE_LINK_PROPERTIES {
  path: string
  title: string
}
