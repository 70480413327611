import React from 'react'

import { IconProps } from './types'

const Arrow = (props: IconProps): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    width={props.width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || '#000'}
      d="M8.468 16.543h7.07a1 1 0 00.383-.085 1 1 0 00.537-.537 1 1 0 00.085-.382V8.47a1.005 1.005 0 00-2.008 0l.007 4.659L9.175 7.76A1 1 0 007.76 9.175l5.367 5.367-4.66-.007a.998.998 0 00-1.004 1.004 1 1 0 001.004 1.004z"
    />
  </svg>
)

export default Arrow
