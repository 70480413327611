import en from './en/translation.json'
import af from './af/translation.json'

const resources = {
  en: {
    translation: en,
  },
  af: {
    translation: af,
  },
}
export default resources
