export enum AvailableMaps {
  district = 'district',
  province = 'province',
  national = 'national',
}

export interface MapState {
  district: boolean
  province: boolean
  national: boolean
}
