import { __DEV__ } from '../../constants'
import { EnvVars } from './env.types'

export const conditionAndIsDev = (condition: boolean): boolean =>
  __DEV__ && condition

export const getEnvVar = (
  envVar: EnvVars,
  env: any = process.env,
): string | undefined => env[`REACT_APP_${envVar}`]

export const getBooleanEnvVar = (
  envVar: EnvVars,
  env: any = process.env,
): boolean => getEnvVar(envVar, env) === 'true'
