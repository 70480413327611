import React, { FunctionComponent, useEffect, useState } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { WithTranslation } from 'react-i18next'
import { equals, map, prop } from 'ramda'

import styles from './NavBar.module.scss'
import Info from '../../common/components/Icons/Info'
import Chevron from '../../common/components/Icons/Chevron'
import Burger from '../../common/components/Icons/Burger'
import Close from '../../common/components/Icons/Close'
import { NavLinkItem } from './types'
import { debounce } from '../../common/utils/functions'
import { generateReactKey } from '../../common/utils/hooks.utils'
import { DISTRICT_PATHS, DISTRICT_NAMES } from '../District/district.types'
import { PROVINCE_PATHS, PROVINCE_NAMES } from '../Province/Province.types'
import { getProvinceAndDistrictLinkProperties } from '../../common/utils/data'

const NavBar: FunctionComponent<WithTranslation> = ({ i18n, t }) => {
  const [showMobileMenu, toggleMobileMenu] = useState(false)
  const [showBackToDashboard, setShowBackToDashboard] = useState(false)
  const [showNavbar, setShowNavbar] = useState(true)
  const [showNavDropdown, setShowNavDropdown] = useState(false)
  const [showNavDropdownChild, setShowNavDropdownChild] = useState(null)

  const provinceLinks = getProvinceAndDistrictLinkProperties(
    PROVINCE_PATHS,
    DISTRICT_PATHS,
    PROVINCE_NAMES,
    DISTRICT_NAMES,
  )

  const setChildNavDropdown = (path) => (event) => {
    event.stopPropagation()
    setShowNavDropdownChild(equals(path, showNavDropdownChild) ? '' : path)
  }

  const renderProvinceLink = ({ path, title, children }) =>
    path && title && children.length ? (
      <li
        key={path}
        className={`${styles.provinceDropdown} ${
          showNavDropdownChild === path ? styles.active : ''
        }`}
        onClick={setChildNavDropdown(path)}
      >
        <NavLink to={path}>{t(title)}</NavLink>
        <Chevron />
        <ul className={styles.navDropdownItems}>
          {map(({ path, title }) => (
            <li key={path}>
              <NavLink to={path}>{t(title)}</NavLink>
            </li>
          ))(children)}
        </ul>
      </li>
    ) : path && title ? (
      <li key={path}>
        <NavLink to={path}>{t(title)}</NavLink>
      </li>
    ) : null

  const renderDropDown = (): JSX.Element => (
    <div
      className={`${styles.navDropdown} ${
        showNavDropdown ? styles.active : ''
      }`}
      onClick={() => setShowNavDropdown(!showNavDropdown)}
    >
      <span>{t('South Africa')}</span>
      <Chevron />
      <ul className={styles.navDropdownItems}>
        <li>
          <NavLink to="/">{t('South Africa')}</NavLink>
        </li>
        <ul>{map(renderProvinceLink, provinceLinks)}</ul>
      </ul>
    </div>
  )

  const renderExternalLinks = (link) => (
    <li key={generateReactKey()} className={styles.menuItem}>
      <a href={link.path} target="_blank" rel="noopener noreferrer">
        {t(link.name)}
      </a>
    </li>
  )

  const renderMainNavLink = (link: NavLinkItem): JSX.Element => (
    <li key={generateReactKey()} className={styles.menuItem}>
      <HashLink to={{ hash: link.path }}>{t(link.name)}</HashLink>
    </li>
  )
  const renderMobileNavLink = (link: NavLinkItem): JSX.Element => (
    <li key={link.name} className={styles.menuItem}>
      <HashLink
        onClick={() => {
          toggleMobileMenu(false)
        }}
        to={{ hash: link.path }}
      >
        {t(link.name)}
      </HashLink>
    </li>
  )

  const topBarLinks = t('media24Links', { returnObjects: true })
  const mainNavLinks = t('mainNavLinks', { returnObjects: true })

  const renderSignInButtons = (): JSX.Element => (
    <div className={styles.menuActions}>
      <a
        href={t('signUpLink')}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.menuActionPrimary}
      >
        {t('Sign up for free')}
      </a>
      <a
        href={t('signInLink')}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.menuActionSecondary}
      >
        {t('Sign in')}
      </a>
    </div>
  )

  useEffect(() => {
    let lastScrollTop = 0

    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      scrollTop > lastScrollTop ? setShowNavbar(false) : setShowNavbar(true)
      lastScrollTop = scrollTop
    }

    window.addEventListener('scroll', () => debounce(handleScroll, 100), false)
  }, [])

  const location = useLocation()
  useEffect(() => {
    setShowBackToDashboard(prop('pathname', location) === '/about-the-data')
    toggleMobileMenu(false)
  }, [location])

  return (
    <>
      <div
        className={`${styles.topBar} ${
          i18n.language === 'en' && styles.news24TopBar
        } `}
      >
        <div className={styles.topBarContainer}>
          {topBarLinks ? (
            <>
              <ul className={styles.topBarMenuItems}>
                {map(renderExternalLinks, topBarLinks)}
              </ul>
              {renderSignInButtons()}
            </>
          ) : (
            <>
              <a
                className={styles.backToMainSite}
                href="https://www.netwerk24.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Chevron width="16" fill="#696969" /> Terug na Netwerk24
              </a>

              <div className={styles.menuActions}>
                <a
                  href={t('signInLink')}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.menuActionNetwerk}
                >
                  {"Nog nie 'n intekenaar? Klik hier"}
                </a>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={`${styles.navBar} ${showNavbar ? styles.active : ''}`}>
        <div className={styles.navHeader}>
          <Link to="/" className={styles.logoWrapper}>
            {i18n.language === 'en' && (
              <>
                <img
                  className={`${styles.logo} ${styles.mobileLogo}`}
                  src="/images/news24-logo-small.svg"
                  alt="News24 logo"
                />
                <img
                  className={`${styles.logo} ${styles.desktopLogo}`}
                  src="/images/news24-logo.svg"
                  alt="News24 logo"
                />
              </>
            )}
            {i18n.language === 'af' && (
              <>
                <img
                  className={`${styles.logo} ${styles.mobileLogo}`}
                  src="/images/netwerk24-logo-small.svg"
                  alt="Netwerk24 logo"
                />
                <img
                  className={`${styles.logo} ${styles.desktopLogo}`}
                  src="/images/netwerk24-logo.svg"
                  alt="Netwerk24 logo"
                />
              </>
            )}
            <div className={styles.siteTitle}>
              <h2>Covid-19</h2>
              <h6 className={i18n.language === 'af' ? 'hide-on-mobile' : ''}>
                {t('dashboard')}
              </h6>
            </div>
          </Link>
          {!showBackToDashboard && (
            <div>
              <Link className={styles.aboutTheDataLink} to="/about-the-data">
                <Info width="16px" /> {t('About the data')}
              </Link>
            </div>
          )}
        </div>

        <div className={styles.navBody}>
          {!showBackToDashboard && (
            <>
              {renderDropDown()}
              <ul className={styles.desktopNav}>
                {map(renderMainNavLink, mainNavLinks)}
              </ul>
            </>
          )}

          <div
            className={styles.mobileMenuTrigger}
            onClick={() => toggleMobileMenu(!showMobileMenu)}
          >
            <Burger />
          </div>
          {showMobileMenu && (
            <div className={styles.mobileNavWrapper}>
              <div
                className={styles.mobileMenuClose}
                onClick={() => toggleMobileMenu(!showMobileMenu)}
              >
                <Close />
              </div>
              {renderDropDown()}
              <ul className={styles.mobileNav}>
                {map(renderMobileNavLink, mainNavLinks)}
              </ul>
              <div>
                <Link className={styles.aboutTheDataLink} to="/about-the-data">
                  <Info width="16px" /> {t('About the data')}
                </Link>
              </div>

              {i18n.language === 'en' ? (
                <>
                  {renderSignInButtons()}
                  <ul className={styles.menuItems}>
                    {map(renderExternalLinks, topBarLinks)}
                  </ul>
                </>
              ) : (
                <>
                  <div className={styles.menuActions}>
                    <a
                      href={t('signInLink')}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.menuActionNetwerk}
                    >
                      {"Nog nie 'n intekenaar? Klik hier"}
                    </a>
                  </div>

                  <a
                    className={styles.backToMainSite}
                    href="https://www.netwerk24.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Chevron width="16" fill="#696969" /> Terug na Netwerk24
                  </a>
                </>
              )}
            </div>
          )}
        </div>

        {showBackToDashboard && (
          <Link to="/" className={styles.navBack}>
            <Chevron width="16" fill="#696969" /> {t('Back to Dashboard')}
          </Link>
        )}
      </div>
    </>
  )
}

export default NavBar
