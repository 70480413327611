import React, { FC } from 'react'
import { SwiperSlide } from 'swiper/react'
import SwiperInit from '../SwiperInit'

import styles from './ArticleCarouselBox.module.scss'
import { Article } from './types'
import ArticleSlide from './ArticleSlide'

interface Props {
  title: string
  articles: Article[]
  id: string
}

const ArticleCarouselBox: FC<Props> = ({ title, articles, id }) => {
  const prevId = `${id}Prev`
  const nextId = `${id}Next`

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{title}</h4>
      <SwiperInit
        navigation={{
          prevEl: `#${prevId}`,
          nextEl: `#${nextId}`,
        }}
        slidesPerView={1}
        loop={true}
        spaceBetween={10}
        breakpoints={{
          480: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
        }}
      >
        {articles.map((article, index) => (
          <SwiperSlide key={index}>
            <ArticleSlide {...article} />
          </SwiperSlide>
        ))}
      </SwiperInit>
      <div id={prevId} className="swiperButtonPrev button" />
      <div id={nextId} className="swiperButtonNext button" />
    </div>
  )
}

export default ArticleCarouselBox
