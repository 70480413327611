import React from 'react'

import { IconProps } from './types'

const Burger = (props: IconProps): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    width={props.width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || '#000'}
      d="M1 4.889C1 4.398 1.398 4 1.889 4h19.555a.889.889 0 110 1.778H1.89a.889.889 0 01-.89-.89zM1 12a.89.89 0 01.889-.889h19.555a.889.889 0 110 1.778H1.89A.889.889 0 011 12zm0 7.111a.89.89 0 01.889-.889h19.555a.889.889 0 110 1.778H1.89a.889.889 0 01-.89-.889z"
    />
  </svg>
)

export default Burger
