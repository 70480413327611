import React, { FunctionComponent } from 'react'
import ZingChart from 'zingchart-react'
import { mergeDeepRight } from 'ramda'

interface Props {
  chartData: any
}

const BarChart: FunctionComponent<Props> = ({ chartData }) => {
  const chartConfig = mergeDeepRight(
    {
      type: 'bar',
      globals: {
        fontFamily: 'Proxima Nova, Helvetica, Sans Serif',
        fontSize: '14px',
        fontColor: '#a4a4a4',
        fontWeight: 'normal',
        lineColor: '#CCCCCC',
        tick: {
          lineColor: '#CCCCCC',
        },
        plotarea: {
          borderRadius: '0 0 0 8',
          borderRight: '0px',
          borderTop: '0px',
          margin: 'dynamic',
          borderLeft: '1px solid #696969',
          borderBottom: '1px solid #696969',
        },
      },
      scaleY: {
        zooming: true,
        lineWidth: 0,
        item: {
          padding: '0 8 0 0',
          rules: [
            {
              rule: '%i == 0',
              offsetX: 8,
              offsetY: 8,
              backgroundColor: 'none',
            },
          ],
        },
        tick: {
          lineColor: '#e2e2e2',
          rules: [
            {
              rule: '%i == 0',
              visible: false,
            },
          ],
        },
        guide: {
          visible: true,
          lineColor: '#e2e2e2',
          lineStyle: 'dotted',
          lineGapSize: '4px',
          rules: [
            {
              rule: '%i == 0',
              visible: false,
            },
          ],
        },
      },
      scaleX: {
        zooming: true,
        offsetY: -16,
        lineWidth: 0,
        padding: 16,
        margin: 16,
        label: {
          paddingTop: '8px',
        },
        item: {
          padding: 4,
        },
        tick: {
          lineColor: '#e2e2e2',
          rules: [
            {
              rule: '%i == 0',
              visible: false,
            },
          ],
        },
      },
      gui: {
        contextMenu: {
          empty: true,
        },
      },
      plot: {
        borderRadius: '8px 8px 0px 0px',
        selectionMode: 'plot',
        fillType: 'none',
        thousandsSeparator: ' ',
        hoverState: {
          backgroundColor: '#a4a4a4',
          alpha: '.24',
        },
      },
      tooltip: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '16px 16px',
        fontColor: '#212121',
        borderColor: '#f5f5f5',
        shadow: 'false',
        callout: true,
      },
    },
    chartData,
  )

  return <ZingChart data={chartConfig} />
}

export default BarChart
