import React, { SFC } from 'react'
import { prop, map } from 'ramda'

import styles from './Tickers.module.scss'
import Arrow from '../../common/components/Icons/Arrow'
import { TickerData } from './types'
import { formatNumberWithSpaces } from '../../common/utils/functions'

interface Props {
  tickerData: TickerData[]
}

const Tickers: SFC<Props> = ({ tickerData }) => {
  return (
    <div className={styles.tickersWrapper}>
      {map(
        (ticker) => (
          <div key={prop('title', ticker)} className={styles.ticker}>
            {prop('title', ticker)}
            {prop('movement', ticker) && (
              <div
                className={
                  prop('movementPositive', ticker) ? styles.positive : ''
                }
              >
                <span
                  className={prop('movementDirection', ticker) ? styles.up : ''}
                >
                  <Arrow />
                </span>
                {prop('movement', ticker)}
              </div>
            )}
            <h2>{formatNumberWithSpaces(prop('amount', ticker))}</h2>
          </div>
        ),
        tickerData,
      )}
    </div>
  )
}

export default Tickers
