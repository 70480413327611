import React, { FunctionComponent } from 'react'
import ZingChart from 'zingchart-react'
import { mergeDeepRight } from 'ramda'

interface Props {
  chartData: any
}

const StackedBarAndLine: FunctionComponent<Props> = ({ chartData }) => {
  const chartConfig = mergeDeepRight(
    {
      type: 'bar',
      globals: {
        fontFamily: 'Proxima Nova, Helvetica, Sans Serif',
        fontSize: '14px',
        fontColor: '#a4a4a4',
        fontWeight: 'normal',
        lineColor: '#CCCCCC',
        tick: {
          lineColor: '#CCCCCC',
        },
        plotarea: {
          borderRadius: '0 0 0 8',
          margin: 'dynamic',
        },
        scaleX: {
          zooming: true,
          offsetY: -16,
          lineWidth: 0,
          padding: 16,
          margin: 16,

          label: {
            paddingTop: '8px',
          },
          item: {
            padding: 4,
            rules: [
              {
                rule: '%i == 0',
                visible: false,
              },
            ],
          },
          tick: {
            lineColor: '#e2e2e2',
            rules: [
              {
                rule: '%i == 0',
                visible: false,
              },
            ],
          },
        },

        scaleY: {
          zooming: true,
          lineWidth: 0,
          short: true,
          item: {
            padding: '0 8 0 0',
            rules: [
              {
                rule: '%i == 0',
                offsetX: 8,
                offsetY: 8,
                backgroundColor: 'none',
              },
            ],
          },
          tick: {
            lineColor: '#e2e2e2',
            rules: [
              {
                rule: '%i == 0',
                visible: false,
              },
            ],
          },
          guide: {
            visible: true,
            lineColor: '#e2e2e2',
            lineStyle: 'dotted',
            lineGapSize: '4px',
            rules: [
              {
                rule: '%i == 0',
                visible: false,
              },
            ],
          },
        },
      },

      gui: {
        contextMenu: {
          empty: true,
        },
      },

      legend: {
        border: 'none',
        layout: 'float',
        align: 'center',
        item: {
          fontColor: '#21211',
          fontWeight: '500',
        },
        marker: {
          borderRadius: '4px',
          height: '16px',
          width: '16px',
        },
      },

      crosshairX: {
        lineWidth: '0',
        plotLabel: {
          backgroundColor: '#fff',
          borderRadius: '8px',
          padding: '16px 16px',
          borderColor: '#f5f5f5',
          fontColor: '#4a4a4a',
          thousandsSeparator: ' ',
        },
        scaleLabel: {
          backgroundColor: '#ccc',
          fontColor: '#212121',
          borderRadius: '40px',
          callout: false,
          padding: '4px 8px',
          fontSize: '12px',
        },
      },

      tooltip: {
        fontFamily: '"Proxima Nova", sans-serif',
        visible: false,
      },

      plot: {
        stacked: true,
        stackType: 'normal',
        borderRadius: '4px 4px 0px 0px',
      },
    },
    chartData,
  )

  return <ZingChart data={chartConfig} />
}

export default StackedBarAndLine
