import React, { SFC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter as Router } from 'connected-react-router'
import { Helmet } from 'react-helmet'

import history from '../../common/redux/history'
import NavBar from '../NavBar'
import { Country } from '../Country'
import { Province } from '../Province'
import { District } from '../District'
import NotFound from './components/NotFound'
import { AboutTheData } from '../AboutTheData'
import Footer from '../Footer'
import '../../common/styles/global.scss'

const Component: SFC = () => {
  const language = process.env.REACT_APP_LANGUAGE
  return (
    <div className={`App theme-${language}`}>
      <main className="App__main">
        <Router history={history}>
          <NavBar />
          <Switch>
            <Route exact path="/" component={Country} />
            <Route exact path="/eastern-cape" component={Province} />
            <Route exact path="/free-state" component={Province} />
            <Route exact path="/gauteng" component={Province} />
            <Route exact path="/limpopo" component={Province} />
            <Route exact path="/mpumalanga" component={Province} />
            <Route exact path="/northern-cape" component={Province} />
            <Route exact path="/north-west" component={Province} />
            <Route exact path="/kwazulu-natal" component={Province} />
            <Route exact path="/western-cape" component={Province} />
            <Route
              exact
              path="/western-cape/city-of-cape-town"
              component={District}
            />
            <Route exact path="/western-cape/west-coast" component={District} />
            <Route
              exact
              path="/western-cape/cape-winelands"
              component={District}
            />
            <Route exact path="/western-cape/overberg" component={District} />
            <Route
              exact
              path="/western-cape/central-karoo"
              component={District}
            />
            <Route
              exact
              path="/western-cape/garden-route"
              component={District}
            />
            <Route exact path="/gauteng/sedibeng" component={District} />
            <Route exact path="/gauteng/west-rand" component={District} />
            <Route exact path="/gauteng/ekurhuleni" component={District} />
            <Route exact path="/gauteng/johannesburg" component={District} />
            <Route exact path="/gauteng/tshwane" component={District} />
            <Route exact path="/about-the-data" component={AboutTheData} />
            <Route path="*" component={NotFound} />
          </Switch>
          <Footer />
        </Router>
      </main>
      {language === 'af' && (
        <Helmet>
          <link
            rel="shortcut icon"
            href="/images/netwerk24-favicon/favicon.ico"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/images/netwerk24-favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/images/netwerk24-favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/images/netwerk24-favicon/favicon-16x16.png"
          />
          <link
            rel="manifest"
            href="/images/netwerk24-favicon/site.webmanifest"
          />
          <link
            rel="mask-icon"
            href="/images/netwerk24-favicon/safari-pinned-tab.svg"
            color="#212121"
          />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="theme-color" content="#ffffff" />

          <script
            type="text/javascript"
            src="/scripts/netwerk24-effective-measure.js"
          ></script>
        </Helmet>
      )}
      {language === 'en' && (
        <Helmet>
          <link rel="shortcut icon" href="/images/news24-favicon/favicon.ico" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/images/news24-favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/images/news24-favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/images/news24-favicon/favicon-16x16.png"
          />
          <link rel="manifest" href="/images/news24-favicon/site.webmanifest" />
          <link
            rel="mask-icon"
            href="/images/news24-favicon/safari-pinned-tab.svg"
            color="#212121"
          />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="theme-color" content="#ffffff" />

          <script
            type="text/javascript"
            src="/scripts/news24-narrative-tracking.js"
          ></script>
        </Helmet>
      )}
    </div>
  )
}

export default Component
