import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import MostReadArticles from './MostReadArticles'

import selector from './MostReadArticles.selector'

const mapStateToProps = selector

export default connect(mapStateToProps)(withTranslation()(MostReadArticles))
