import React, { FC } from 'react'

import DateTime from '../../../modules/DateTime'
import { Article } from './types'
import styles from './ArticleSlide.module.scss'

const ArticleSlide: FC<Article> = ({ date, title, image, link }) => {
  return (
    <div className={styles.container}>
      <a
        className={styles.articleLink}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={image} alt={title} className={styles.image} />
        <h4 className={styles.title}>{title}</h4>
        {date && (
          <div className={styles.dateWrap}>
            <DateTime date={new Date(date).getTime()} style={{ margin: 0 }} />
          </div>
        )}
      </a>
    </div>
  )
}

export default ArticleSlide
