import React, { FunctionComponent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { compose, find, map, path, prop, propEq, toPairs } from 'ramda'
import { WithTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import MainContent from '../MainContent'
import { DailyChangesData, PageContentSection } from '../Country/types'
import StackedBarChart from '../../common/components/Charts/StackedBarChart'
import StackedBarAndLine from '../../common/components/Charts/StackedBarAndLine'
import ContentSection from '../ContentSection'
import { Map } from '../Maps/components/Map'
import Tickers from '../Tickers'
import {
  calculateMapItemAlphaValue,
  createPercentageString,
  getDistrictLinkProperties,
  getPrettyDate,
  getRegionName,
} from '../../common/utils/data'
import Line from '../../common/components/Charts/Line'
import Loader from '../../common/components/Loader'
import { formatNumberWithSpaces } from '../../common/utils/functions'
import AdsProvider, { AdSlot, AdNames, AdPlatforms, AdSections } from '../Ads'
import { brandColors } from '../../common/components/Charts/chartOptions'
import { PROVINCE_NAMES, PROVINCE_PATHS } from './Province.types'
import { DISTRICT_NAMES, DISTRICT_PATHS } from '../District/district.types'
import FeatureArticle from '../FeatureArticle'
import RelatedArticles from '../RelatedArticles'
import MostReadArticles from '../MostReadArticles'

interface Props {
  provinceCode: string
  mainContent: PageContentSection
  dailyCasesContent: PageContentSection
  provincialCasesContent: PageContentSection
  deathsContent: PageContentSection
  hospitalisationsContent: PageContentSection
  dailyChanges: DailyChangesData
  currentlyAdmitted: number[]
  icuCases: number[]
  onVentilation: number[]
  nationalDeaths: number[]
  provincialDeaths: number[]
  provincialRecoveries: number[]
  provincialActiveCases: number[]
  provincialDailyCases: number[]
  provincialDailyCasesDates: string[]
  statisticsDates: string[]
  hospitalisationsDates: string[]
  latestProvincialStatistics: any
  latestNationalStatistics: any
  dailyCasesMovingAverage: number[]
}

const Province: FunctionComponent<Props & WithTranslation> = ({
  t,
  i18n,
  provinceCode,
  mainContent,
  dailyCasesContent,
  provincialCasesContent,
  deathsContent,
  hospitalisationsContent,
  dailyChanges,
  currentlyAdmitted,
  icuCases,
  onVentilation,
  nationalDeaths,
  provincialDeaths,
  provincialRecoveries,
  provincialActiveCases,
  provincialDailyCases,
  provincialDailyCasesDates,
  statisticsDates,
  hospitalisationsDates,
  latestProvincialStatistics,
  latestNationalStatistics,
  dailyCasesMovingAverage,
}) => {
  const history = useHistory()

  const tickerData = [
    {
      title: 'Total Cases',
      amount: prop('confirmed', latestNationalStatistics),
      movement: createPercentageString(
        prop('confirmedPercentage', dailyChanges),
      ),
      movementDirection: path(['confirmedPercentage'], dailyChanges) > 0,
      movementPositive: path(['confirmedPercentage'], dailyChanges) < 0,
    },
    {
      title: 'Total Deaths',
      amount: prop('deaths', latestNationalStatistics),
      movement: createPercentageString(prop('deathsPercentage', dailyChanges)),
      movementDirection: prop('deathsPercentage', dailyChanges) > 0,
      movementPositive: prop('deathsPercentage', dailyChanges) < 0,
    },
    {
      title: 'Active Cases',
      amount: prop('active', latestNationalStatistics),
      movement: createPercentageString(prop('activePercentage', dailyChanges)),
      movementDirection: prop('activePercentage', dailyChanges) > 0,
      movementPositive: prop('activePercentage', dailyChanges) < 0,
    },
    {
      title: 'Recoveries',
      amount: prop('recoveries', latestNationalStatistics),
      movement: createPercentageString(
        prop('recoveriesPercentage', dailyChanges),
      ),
      movementDirection: prop('recoveriesPercentage', dailyChanges) > 0,
      movementPositive: prop('recoveriesPercentage', dailyChanges) > 0,
    },
    {
      title: 'New Cases',
      amount: prop('newCases', dailyChanges),
      movement: '',
      movementDirection: false,
      movementPositive: false,
    },
    {
      title: 'New Deaths',
      amount: prop('newDeaths', dailyChanges),
      movement: '',
      movementDirection: false,
      movementPositive: false,
    },
    {
      title: 'Hospitalisations',
      amount: prop('hospitalisations', dailyChanges),
      movement: createPercentageString(
        prop('hospitalisationsPercentage', dailyChanges),
      ),
      movementDirection: prop('hospitalisationsPercentage', dailyChanges) > 0,
      movementPositive: prop('hospitalisationsPercentage', dailyChanges) < 0,
    },
  ]

  const getAlpha = (region, provinceCode) =>
    calculateMapItemAlphaValue(
      path([region, 'confirmed'], latestProvincialStatistics) /
        path([provinceCode, 'confirmed'], latestProvincialStatistics),
    )

  const generateMapConfigItem = (region) => ({
    'data-date': getPrettyDate(
      path([region, 'date'], latestProvincialStatistics),
    ),
    'data-total-cases': formatNumberWithSpaces(
      path([region, 'confirmed'], latestProvincialStatistics),
    ),
    'data-recovered': formatNumberWithSpaces(
      path([region, 'recoveries'], latestProvincialStatistics),
    ),
    'backgroundColor': '#dd1f26',
    'alpha': getAlpha(region, provinceCode),
    'hoverState': {
      alpha: 1,
      backgroundColor: brandColors[i18n.language],
    },
    'data-active-cases': formatNumberWithSpaces(
      path([region, 'active'], latestProvincialStatistics),
    ),
    'data-deaths': formatNumberWithSpaces(
      path([region, 'deaths'], latestProvincialStatistics),
    ),
  })

  const provinceMapItems =
    provinceCode === 'WC'
      ? {
          COCT: generateMapConfigItem(DISTRICT_NAMES.COCT),
          WestCoast: generateMapConfigItem(DISTRICT_NAMES.WestCoast),
          CapeWinelands: generateMapConfigItem(DISTRICT_NAMES.CapeWinelands),
          Overberg: generateMapConfigItem(DISTRICT_NAMES.Overberg),
          GardenRoute: generateMapConfigItem(DISTRICT_NAMES.GardenRoute),
          CentralKaroo: generateMapConfigItem(DISTRICT_NAMES.CentralKaroo),
        }
      : provinceCode === 'GP'
      ? {
          Sedibeng: generateMapConfigItem(DISTRICT_NAMES.Sedibeng),
          WestRand: generateMapConfigItem(DISTRICT_NAMES.WestRand),
          Ekurhuleni: generateMapConfigItem(DISTRICT_NAMES.Ekurhuleni),
          COJ: generateMapConfigItem(DISTRICT_NAMES.COJ),
          COT: generateMapConfigItem(DISTRICT_NAMES.COT),
        }
      : { [provinceCode]: generateMapConfigItem(provinceCode) }

  const mapOptions = {
    tooltip: {
      'callout': true,
      'text':
        provinceCode === 'WC' || provinceCode === 'GP'
          ? `<div class="tooltip"><div class="title"><h6>%text</h6><span>%data-date</span></div><div class="stat">Total Cases <div class="amount">%data-total-cases</div></div><div class="stat">Recovered<div class="amount">%data-recovered</div></div></div>`
          : `<div class="tooltip"><div class="title"><h6>%text</h6><span>%data-date</span></div><div class="stat">Total Cases <div class="amount">%data-total-cases</div></div><div class="stat">Active cases <div class="amount">%data-active-cases</div></div><div class="stat">Recovered<div class="amount">%data-recovered</div></div><div class="stat">Deaths<div class="amount">%data-deaths</div></div></div>`,
      'html-mode': true,
      'backgroundColor': '#fff',
      'borderRadius': '4px',
      'padding': '0',
      'fontColor': '#212121',
      'borderColor': '#f5f5f5',
      'shadow': 'false',
      'font-family': '"Proxima Nova", sans-serif',
    },
    items: provinceMapItems,
  }

  const getDailyCasesChartData = () => ({
    scaleY: {
      label: {
        text: 'Number of cases',
      },
      short: true,
    },
    scaleX: {
      label: {
        text: 'Date',
      },
      values: provincialDailyCasesDates,

      step: 'day',
      transform: {
        type: 'date',
        all: '%M %d',
      },
    },
    plot: { stacked: false },
    series: [
      {
        type: 'bar',
        text: 'Cases',
        values: provincialDailyCases,
        backgroundColor: '#4973D5',
        border: '1px solid #fff',
      },
      {
        type: 'line',
        text: '7-Day Moving average',
        values: dailyCasesMovingAverage,
        lineColor: '#21418D',
      },
    ],
  })

  const getProvincialCasesData = () => ({
    scaleY: {
      label: {
        text: 'Number of cases',
      },
      short: true,
    },
    scaleX: {
      label: {
        text: 'Date',
      },
      values: statisticsDates,
      step: 'day',
      transform: {
        type: 'date',
        all: '%M %d',
      },
    },
    series: [
      {
        values: provincialActiveCases,
        backgroundColor: '#A4B9EA',
        text: 'Active',
        border: '1px solid #fff',
      },
      {
        values: provincialDeaths,
        backgroundColor: '#162B5E',
        text: 'Deaths',
        border: '1px solid #fff',
      },
      {
        values: provincialRecoveries,
        backgroundColor: '#4973D5',
        text: 'Recoveries',
        border: '1px solid #fff',
      },
    ],
  })

  const deathsChartConfig = () => ({
    scaleY: {
      label: {
        text: 'Number of deaths',
      },
      short: true,
    },
    scaleX: {
      label: {
        text: 'Date',
      },
      values: statisticsDates,
      step: 'day',
      transform: {
        type: 'date',
        all: '%M %d',
      },
    },
    series: [
      {
        text: 'National deaths',
        values: nationalDeaths,
        lineColor: '#7796DF',
      },
      {
        text: `${getRegionName(provinceCode, PROVINCE_NAMES)} deaths`,
        values: provincialDeaths,
        lineColor: '#21418D',
      },
    ],
  })

  const hospitalisationsChartConfig = () => ({
    scaleY: {
      label: {
        text: 'Number of cases',
      },
      short: true,
    },
    scaleX: {
      label: {
        text: 'Date',
      },
      values: hospitalisationsDates,
      step: 'day',
      transform: {
        type: 'date',
        all: '%M %d',
      },
    },
    series: [
      {
        text: 'Admitted',
        values: currentlyAdmitted,
        lineColor: '#162b5e',
      },
      {
        text: 'Admitted to ICU',
        values: icuCases,
        lineColor: '#dd1f26',
      },
      {
        text: 'On Ventilation',
        values: onVentilation,
        lineColor: '#a4b9ea',
      },
    ],
  })

  const renderDistrictLink = ({ path, title }) => {
    return path && title ? (
      <Link key={path} className="button" to={path}>
        {t(title)}
      </Link>
    ) : null
  }
  const districtLinks = getDistrictLinkProperties(
    provinceCode,
    PROVINCE_PATHS,
    DISTRICT_NAMES,
    DISTRICT_PATHS,
  )

  return (
    <div className="container">
      <Helmet>
        <title>{`${t('siteTitle')} | ${t(
          getRegionName(provinceCode, PROVINCE_NAMES),
        )}`}</title>
      </Helmet>
      <AdsProvider>
        <AdSlot section={AdSections.PROVINCIAL} name={AdNames.LB1} />
        <AdSlot
          section={AdSections.PROVINCIAL}
          name={AdNames.STICKY}
          platform={AdPlatforms.MOB_WEB}
        />
        {mainContent && (
          <MainContent
            title={t(
              `Covid-19 in ${getRegionName(provinceCode, PROVINCE_NAMES)}`,
            )}
            date={prop('last_updated_on', mainContent)}
            content={prop('content', mainContent)}
          >
            {latestProvincialStatistics ? (
              <>
                <Map
                  mapName={provinceCode}
                  mapOptions={mapOptions}
                  shapeClick={(shape) => {
                    const provincePath = compose(
                      prop(0),
                      find(propEq(1, shape.shapeid)),
                      toPairs,
                    )(DISTRICT_PATHS)

                    if (shape && provincePath) {
                      history.push(provincePath)
                    }
                  }}
                />
                <Tickers tickerData={tickerData} />
              </>
            ) : (
              <Loader />
            )}
          </MainContent>
        )}
        <ContentSection sectionName="daily-cases" content={dailyCasesContent}>
          {latestProvincialStatistics ? (
            <StackedBarAndLine chartData={getDailyCasesChartData()} />
          ) : (
            <Loader />
          )}
        </ContentSection>
        <AdSlot section={AdSections.PROVINCIAL} name={AdNames.LB2} />
        <FeatureArticle />
        <ContentSection
          sectionName="provincial-cases"
          content={provincialCasesContent}
        >
          {latestProvincialStatistics ? (
            <StackedBarChart chartData={getProvincialCasesData()} />
          ) : (
            <Loader />
          )}
        </ContentSection>
        <AdSlot
          section={AdSections.PROVINCIAL}
          name={AdNames.MREC1}
          platform={AdPlatforms.MOB_WEB}
        />
        <ContentSection sectionName="deaths" content={deathsContent}>
          {latestProvincialStatistics ? (
            <Line chartData={deathsChartConfig()} />
          ) : (
            <Loader />
          )}
        </ContentSection>
        <MostReadArticles />
        <AdSlot
          section={AdSections.PROVINCIAL}
          name={AdNames.MREC2}
          platform={AdPlatforms.MOB_WEB}
        />
        <ContentSection
          sectionName="hospitalisations"
          content={hospitalisationsContent}
        >
          {latestProvincialStatistics ? (
            <Line chartData={hospitalisationsChartConfig()} />
          ) : (
            <Loader />
          )}
        </ContentSection>
        <RelatedArticles />
        {districtLinks.length > 0 && (
          <section style={{ marginBottom: '1.5rem' }}>
            <hr />
            <h3>{t('View a District')}</h3>
            {map(renderDistrictLink, districtLinks)}
          </section>
        )}
      </AdsProvider>
    </div>
  )
}

export default Province
