import {
  compose,
  map,
  mapObjIndexed,
  values,
  assoc,
  prop,
  find,
  propEq,
  filter,
  fromPairs,
  keys,
  sortBy,
  equals,
  contains,
  dissoc,
  toPairs,
  reduce,
  append,
  head,
  last,
} from 'ramda'
import {
  DISTRICT_PATHS,
  DISTRICT_NAMES,
  DISTRICT_LINK_PROPERTIES,
} from '../../modules/District/district.types'
import {
  PROVINCE_PATHS,
  PROVINCE_NAMES,
  PROVINCE_LINK_PROPERTIES,
} from '../../modules/Province/Province.types'

export const denormalize = (keyProp: string): any =>
  compose(
    values,
    mapObjIndexed((value, key) => assoc(keyProp, key, value)),
  )

export const calculateMapItemAlphaValue = (percentage: number): number =>
  percentage < 0.01
    ? 0.16
    : percentage < 0.05
    ? 0.32
    : percentage < 0.1
    ? 0.48
    : percentage < 0.2
    ? 0.64
    : percentage < 0.3
    ? 0.88
    : 1

export const createPercentageString = (number: number): string =>
  `${Math.abs(number * 100).toFixed(2)}%`

export const getProvinceCodeFromPath = (allProvinces) => (
  provincePath: string,
): string => compose(prop(0), keys, filter(equals(provincePath)))(allProvinces)

export const getProvincePath = (
  provinceCode: string,
  provincePaths: typeof PROVINCE_PATHS,
): string => prop(provinceCode)(provincePaths)

export const getRegionName = (regionCode: string, regionNames): string =>
  prop(regionCode)(regionNames)

export const getProvinceLinkProperties = (
  provinces,
  provinceNames: typeof PROVINCE_NAMES,
): PROVINCE_LINK_PROPERTIES[] =>
  sortBy(
    prop('title'),
    compose(
      values,
      mapObjIndexed((link, key) => ({
        path: link,
        title: prop(key)(provinceNames),
      })),
    )(provinces),
  )

export const getDistrictLinkProperties = (
  provinceCode: string,
  provincePaths: typeof PROVINCE_PATHS,
  districtNames: typeof DISTRICT_NAMES,
  districtPaths: typeof DISTRICT_PATHS,
): DISTRICT_LINK_PROPERTIES[] => {
  const currentDistrictPaths = compose(
    filter((i) => i !== false),
    values,
    mapObjIndexed((key, link) =>
      contains(prop(provinceCode, provincePaths))(link) ? link : false,
    ),
  )(districtPaths)

  return map((path) => ({
    title: prop(prop(path)(districtPaths))(districtNames),
    path,
  }))(currentDistrictPaths)
}

export const getProvinceAndDistrictLinkProperties = (
  provinces: typeof PROVINCE_PATHS,
  districts: typeof DISTRICT_PATHS,
  provinceNames: typeof PROVINCE_NAMES,
  districtNames: typeof DISTRICT_NAMES,
) => {
  const currentDistricts = sortBy(
    prop('title'),
    compose(
      values,
      mapObjIndexed((link, key) => ({
        path: key,
        title: prop(link)(districtNames),
      })),
    )(districts),
  )

  const getDistrictData = mapObjIndexed((link, key) => ({
    path: link,
    title: prop(key)(provinceNames),
    children: compose(
      filter((i) => i !== false),
      values,
      map((subLink) =>
        contains(link)(prop('path')(subLink)) ? subLink : false,
      ),
    )(currentDistricts),
  }))

  const currentProvinces = sortBy(
    prop('title'),
    compose(values, getDistrictData)(provinces),
  )

  return currentProvinces
}

export const getPrettyDate = (date: number): string => {
  if (date) {
    const dateParts = new Intl.DateTimeFormat('en-UK', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).formatToParts(date)

    const day = compose(prop('value'), find(propEq('type', 'day')))(dateParts)
    const month = compose(
      prop('value'),
      find(propEq('type', 'month')),
    )(dateParts)
    const year = compose(prop('value'), find(propEq('type', 'year')))(dateParts)
    return `${day} ${month} ${year}`
  }
  return ''
}

export const getKeys = compose(keys, fromPairs)

export const getValues = compose(values, fromPairs)

export const getSubDistrictNavItems = compose(
  sortBy(prop(0)),
  keys,
  dissoc('Unallocated'),
)

export const getSubDistrictChartValues = compose(
  reduce(
    (acummulator, currentValue) =>
      append(
        {
          type: 'bar',
          text: head(currentValue),
          values: [prop('confirmed', last(currentValue))],
        },
        acummulator,
      ),
    [],
  ),
  toPairs,
)
