export enum EnvVars {
  API_URL = 'API_URL',
  LANGUAGE = 'LANGUAGE',
  AD_SLOT_BASE = 'AD_SLOT_BASE',
  AD_SLOT_COVID19DASH = 'AD_SLOT_COVID19DASH',
  AD_SLOTS_OVERRIDE_FOR_DEV = 'AD_SLOTS_OVERRIDE_FOR_DEV',
  AD_SLOTS_TEST_ID = 'AD_SLOTS_TEST_ID',
  GEO_JSON_URL = 'GEO_JSON_URL',
  ZINGCHART_LICENSE_KEY = 'ZINGCHART_LICENSE_KEY',
}
