import { AnyAction } from '@reduxjs/toolkit'

//---------------------------------
// actions
//---------------------------------

export const ZC_LOAD_GEO_JSON = '[zingcharts] load geo json'
export const loadGeoJson = (payload: string): AnyAction => ({
  type: ZC_LOAD_GEO_JSON,
  payload,
})

export const ZC_LOAD_GEO_JSON_SUCCESS = '[zingcharts] load geo json success'
export const loadGeoJsonSuccess = (payload: string): AnyAction => ({
  type: ZC_LOAD_GEO_JSON_SUCCESS,
  payload,
})
