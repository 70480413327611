import { combineReducers, Reducer } from 'redux'
import { connectRouter } from 'connected-react-router'

import { reducer as availableMaps } from '../../modules/App'
import { reducer as country } from '../../modules/Country'
import { reducer as province } from '../../modules/Province'
import { reducer as district } from '../../modules/District'
import { reducer as subDistrict } from '../../modules/SubDistrict'
import { reducer as newsArticles } from '../../modules/NewsArticles'
import { History } from 'history'
import { RootState } from './types'

export default (history: History): Reducer<RootState> =>
  combineReducers({
    router: connectRouter(history),
    availableMaps,
    country,
    province,
    district,
    subDistrict,
    newsArticles,
  })
