import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

//---------------------------------
// actions
//---------------------------------

export const API_LOAD_SUB_DISTRICT_PAGE_DATA =
  '[sub district] fetch page content'
export const loadSubDistrictPageData = (payload): AnyAction => ({
  type: API_LOAD_SUB_DISTRICT_PAGE_DATA,
  payload,
})

export const API_LOAD_SUB_DISTRICT_PAGE_DATA_SUCCESS =
  '[sub district] fetch page content success'
export const loadSubDistrictPageDataSuccess = (payload): AnyAction => ({
  type: API_LOAD_SUB_DISTRICT_PAGE_DATA_SUCCESS,
  payload,
})
export const SET_SUB_DISTRICT_PAGE_CONTENT = '[sub district] set page content'
export const setPageContent = (payload): AnyAction => ({
  type: SET_SUB_DISTRICT_PAGE_CONTENT,
  payload,
})

export const SET_SUB_DISTRICT_STATISTICS_DATA =
  '[sub district] set statistics data'
export const setStatisticsData = (payload): AnyAction => ({
  type: SET_SUB_DISTRICT_STATISTICS_DATA,
  payload,
})

export const API_LOAD_SUB_DISTRICT_PAGE_DATA_FAILURE =
  '[sub district] set page content failure'
export const loadPageDataFailure = (): AnyAction => ({
  type: API_LOAD_SUB_DISTRICT_PAGE_DATA_FAILURE,
})

//---------------------------------
// reducers
//---------------------------------

const overwriteContent = (state, { payload }): any =>
  assoc('content', payload, state)

const overwriteStatistics = (state, { payload }): any =>
  assoc('statistics', payload, state)

//---------------------------------
// reducer
//---------------------------------

export const INITIAL_STATE = {
  content: {},
  statistics: {
    movingAverage: { confirmed: {} },
    caseHistory: {
      confirmed: {},
      recoveries: {},
    },
  },
}

export const reducer = createReducer(INITIAL_STATE as any, {
  [SET_SUB_DISTRICT_PAGE_CONTENT]: overwriteContent,
  [SET_SUB_DISTRICT_STATISTICS_DATA]: overwriteStatistics,
})
