import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import { lt } from 'ramda'

import ArticleCarouselBox from '../../common/components/ArticleCarouselBox'
import Optional from '../../common/components/Optional'
import { Article } from '../../common/components/ArticleCarouselBox/types'
import { __NEWS24SITE__ } from '../../common/constants'

interface Props {
  articles: Article[]
}

const RelatedArticles: FC<Props & WithTranslation> = ({ t, articles }) => (
  <Optional condition={lt(3, articles.length) && __NEWS24SITE__}>
    <ArticleCarouselBox
      id="covid19News"
      title={`${t('Covid19News')}`}
      articles={articles}
    />
  </Optional>
)

export default RelatedArticles
